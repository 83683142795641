import React, {useEffect, useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import { changeMaxAnimals, addNewSpawner, removeSavedSpawner } from "../../../redux/levelSlice"
import { InterfaceAllSlices } from "../../../typescript/interfaceAllSlices"
import { InterfaceSpawner } from "../../../typescript/interfaceSpawner"

import {Box, Image, Heading, Text, Button, Icon} from "@chakra-ui/react"
import { motion, AnimatePresence } from "framer-motion"
import {IoCloseSharp as CloseIcon} from "react-icons/io5"

import InputSlider from "../../Inputs/InputSlider"
import InputNumber from "../../Inputs/InputNumber"
import InputButton from "../../Inputs/InputButton"
import InputSelectItem from "../../Inputs/InputSelectItem"
import ImageDynamic from "../../ImageDynamic"

import spawners from "../../../assets/fakeData/spawners"

const Spawners = () => {

  const userStates = useSelector((state:InterfaceAllSlices) => state.user)
  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const dispatch = useDispatch()

  const [blueStoneRatio, setBlueStoneRatio] = useState(19)
  const [yellowStoneRatio, setYellowStoneRatio] = useState(7)
  const [pinkStoneRatio, setPinkStoneRatio] = useState(25)
  const [purpleStoneRatio, setPurpleStoneRatio] = useState(4)
  const [greenStoneRatio, setGreenStoneRatio] = useState(0)
  const [animalChance, setAnimalChance] = useState(45)
  const [droppable1, setDroppable1] = useState({name: "", name2: "", count: 0})
  const [droppable2, setDroppable2] = useState({name: "", name2: "", count: 0})
  const [droppable3, setDroppable3] = useState({name: "", name2: "", count: 0})

  const [colorStones, setColorStones] = useState<InterfaceSpawner[]>([])
  const [droppables, setDroppables] = useState<InterfaceSpawner[]>([])

  function getPlayableItems(){
    const localColorStones:InterfaceSpawner[] = []
    const localDroppables:InterfaceSpawner[] = []
    
    spawners.map((spawner,i)=>{

      if(spawner.type === 0){
        localColorStones.push(spawner)
      } else if(spawner.type === 2 && spawner.biome === levelStates.biomeType.toLowerCase()){
        localDroppables.push(spawner)
      }
      
    })

    setColorStones(localColorStones)
    setDroppables(localDroppables)
  }

  useEffect(()=>{
    getPlayableItems()
  },[])

  function addNewSpawnerFunc(){
    
    const newSpawner = {
      blueStoneRatio: blueStoneRatio,
      yellowStoneRatio: yellowStoneRatio,
      pinkStoneRatio: pinkStoneRatio,
      purpleStoneRatio: purpleStoneRatio,
      greenStoneRatio: greenStoneRatio,
      animalChance: animalChance,
      animal1Type: droppable1.name,
      animal1Type2: droppable1.name2,
      animal2Type: droppable2.name,
      animal2Type2: droppable2.name2,
      animal3Type: droppable3.name,
      animal3Type2: droppable3.name2,
      animal1Count: droppable1.count,
      animal2Count: droppable2.count,
      animal3Count: droppable3.count,
    }

    dispatch(addNewSpawner(newSpawner))
    
  }
  
  return (
    <Box pt="100px" mx="auto" w="500px">
      <Box w="92px" h="92px" border="1px solid #fff" borderRadius="8px" m="0 auto 40px auto" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none">
        <ImageDynamic name={colorStones[0]?.name2} motion="scale" w="52px" h="52px"/>
      </Box>

      <Box display="flex" flexDir="column" flexWrap="wrap" w="100%" mx="auto">

        {
          colorStones.slice(1).map((colorStone, i)=>
            <Box key={i} w="100%" display="flex" py="10px" sx={{"&:last-child": {pb: 0}}}>
              <InputSlider img={colorStone.name2} width="100%" margin="0" min={0} max={100} 
                value={i === 0 ? blueStoneRatio : i === 1 ? yellowStoneRatio : i === 2 ? pinkStoneRatio : i === 3 ? purpleStoneRatio : i === 4 ? greenStoneRatio : 0} 
                onChange={(val) => i === 0 ? setBlueStoneRatio(val) : i === 1 ? setYellowStoneRatio(val) : i === 2 ? setPinkStoneRatio(val) : i === 3 ? setPurpleStoneRatio(val) : i === 4 ? setGreenStoneRatio(val) : null}/>
            </Box>
          )
        }
        
      </Box>

      <Box m="40px 0">
        <InputNumber label="Animal Chance" warning="Value as persentage (%)" inputWidth="100px" divWidth="100%" min={0} max={100} margin="0" value={animalChance} onChange={(val) => setAnimalChance(val)}/>
      </Box>

      <Box display="flex" flexDir="column">
        {
          [...Array(3)].map((x,i)=>
            <Box key={i} display="flex" flexDir="row" mb={i !== 2 ? "20px" : "0"}>
              <InputSelectItem margin="0 20px 0 0" label={`Droppable ${i+1}`} hidePlaceholderIfValueExist placeholder="Select..." dropdownItems={droppables} 
                value={i === 0 ? droppable1.name : i === 1 ? droppable2.name : i === 2 ? droppable3.name : undefined} 
                onClick={(e) => i === 0 ? setDroppable1({...droppable1, name: e.target.dataset.text, name2: e.target.dataset.text2}) : i === 1 ? setDroppable2({...droppable2, name: e.target.dataset.text, name2: e.target.dataset.text2}) : i === 2 ? setDroppable3({...droppable3, name: e.target.dataset.text, name2: e.target.dataset.text2}) : undefined}/>
              <AnimatePresence>
                <Box as={motion.div} initial={{translateX: -10, opacity: 0}} animate={{translateX: 0, opacity: 1}} exit={{opacity: 0}}>
                  <InputNumber margin='0' label="Count" value={i === 0 ? droppable1.count : i === 1 ? droppable2.count : i === 2 ? droppable3.count : 0}  onChange={(val) =>  i === 0 ? setDroppable1({...droppable1, count: Number(val)}) : i === 1 ? setDroppable2({...droppable2, count: Number(val)}) : i === 2 ? setDroppable3({...droppable3, count: Number(val)}) : undefined}/>
                </Box>  
              </AnimatePresence>
            </Box>
          )
        }
      </Box>

      <Box m="40px 0">
        <InputNumber label="Max Animal" warning="*Number of maximum available animal on the screen at the same time. This effects whole level." inputWidth="100px" divWidth="100%" min={0} margin="0" value={levelStates.maxAnimals} onChange={(val) => dispatch(changeMaxAnimals(parseInt(val)))}/>
      </Box>

      <Box display="flex" flexDir="column">
        <Box mb="10px">
          <Heading as="h5" fontSize="12px" fontWeight="600">Saved Spawners</Heading>
        </Box>

        <Box mb="10px">
          <InputButton label="Add New" bg="transparent" hoverBg="#fff" hoverColor="#000" width="100%" margin="0" border="1px solid #fff" onClick={()=>addNewSpawnerFunc()}/>
        </Box>

        <Box display="flex" flexDir="column" pb="25px">
          {
            levelStates.savedSpawners?.map((savedSpawner,i)=>
              <Box key={i} as={motion.div} initial={{translateY: -20, opacity: 0}} animate={{translateY: 0, opacity: 1}} exit={{translateY: 20, opacity: 0}} display="flex" flexDir="row" alignItems="center" border="1px solid #fff" borderRadius="8px" p="16px" mb="10px" pos="relative">
                <Button position="absolute" top="0" right="0" padding="10px" bg="transparent" _hover={{bg: "transparent"}} _active={{bg: "transparent", transform: "scale(.9)"}} onClick={()=>dispatch(removeSavedSpawner(i))}>
                  <Icon as={CloseIcon}/>
                </Button>
                <Box w="72px" h="72px" minW="72px" border="1px solid #fff" borderRadius="8px" mr="15px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none">
                  <ImageDynamic name={colorStones[0].name2} w="42px" h="42px"/>  
                </Box>
                <Box display="flex" flexDir="row" flexWrap="wrap" userSelect="none">

                  {
                    savedSpawner.blueStoneRatio > 0 &&
                    <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                      <ImageDynamic name="blueStone" h="28px" w="auto"/>
                      <Box border="1px solid #fff" borderRadius="4px" w="30px" h="18px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                        <Text fontSize="10px" fontWeight="600">%{savedSpawner.blueStoneRatio}</Text>
                      </Box>
                    </Box>
                  }
                          
                  {
                    savedSpawner.yellowStoneRatio > 0 &&
                    <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                      <ImageDynamic name="yellowStone" h="28px" w="auto"/>
                      <Box border="1px solid #fff" borderRadius="4px" w="30px" h="18px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                        <Text fontSize="10px" fontWeight="600">%{savedSpawner.yellowStoneRatio}</Text>
                      </Box>
                    </Box>

                  }
                  
                  {
                    savedSpawner.pinkStoneRatio > 0 &&
                    <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                      <ImageDynamic name="pinkStone" h="28px" w="auto"/>
                      <Box border="1px solid #fff" borderRadius="4px" w="30px" h="18px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                        <Text fontSize="10px" fontWeight="600">%{savedSpawner.pinkStoneRatio}</Text>
                      </Box>
                    </Box>
                  }
                  
                  {
                    savedSpawner.purpleStoneRatio > 0 &&
                    <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                      <ImageDynamic name="purpleStone" h="28px" w="auto"/>
                      <Box border="1px solid #fff" borderRadius="4px" w="30px" h="18px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                        <Text fontSize="10px" fontWeight="600">%{savedSpawner.purpleStoneRatio}</Text>
                      </Box>
                    </Box>
                  }
                  
                  {
                    savedSpawner.greenStoneRatio > 0 &&
                    <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                      <ImageDynamic name="greenStone" h="28px" w="auto"/>
                      <Box border="1px solid #fff" borderRadius="4px" w="30px" h="18px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                        <Text fontSize="10px" fontWeight="600">%{savedSpawner.greenStoneRatio}</Text>
                      </Box>
                    </Box>
                  }
                  
                  {
                    savedSpawner.animal1Count > 0 &&
                    <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                      <ImageDynamic name={savedSpawner.animal1Type2} h="28px" w="auto"/>
                      <Box border="1px solid #fff" borderRadius="4px" w="30px" h="18px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                        <Text fontSize="10px" fontWeight="600">{savedSpawner.animal1Count}</Text>
                      </Box>
                    </Box>
                  }

                  {
                    savedSpawner.animal2Count > 0 &&
                    <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                      <ImageDynamic name={savedSpawner.animal2Type2} h="28px" w="auto"/>
                      <Box border="1px solid #fff" borderRadius="4px" w="30px" h="18px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                        <Text fontSize="10px" fontWeight="600">{savedSpawner.animal2Count}</Text>
                      </Box>
                    </Box>
                  }

                  {
                    savedSpawner.animal3Count > 0 &&
                    <Box display="flex" flexDir="column" alignItems="center" mr="10px">
                      <ImageDynamic name={savedSpawner.animal2Type2} h="28px" w="auto"/>
                      <Box border="1px solid #fff" borderRadius="4px" w="30px" h="18px" display="flex" justifyContent="center" alignItems="center" mt="5px">
                        <Text fontSize="10px" fontWeight="600">{savedSpawner.animal3Count}</Text>
                      </Box>
                    </Box>
                  }
                </Box>
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  )
}

export default Spawners