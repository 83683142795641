export default [
  {
    menuTitle: "General Grid Features",
    menuItems: [
      "General Level Settings",
      "Description & Comments",
      "Grid Size & Playable Fields",
      "Objectives",
      "Random Stone List",
      "Rewards"
    ]
  },
  {
    menuTitle: "Game Asset Features",
    menuItems: [
      "Playable Items",
      "Blockers",
      "Cell Features",
      "Spawners",
      "Power-Ups"
    ]
  }
]