import React, { FC, MutableRefObject } from "react"
import { Box, Text, Input, SystemStyleObject } from "@chakra-ui/react"

interface Props {
  name?: string
  loading?: boolean
  width?: string,
  marginBottom?: string,
  label?: string,
  biggerLabel?: boolean
  placeholder?: string
  ref?: any
  sx?: SystemStyleObject
  type: string,
  value: string,
  onKeyDown?: (e: any) => void
  onChange: (e: any) => void
}

const InputText:FC<Props> = (props:Props) => {
  return (
    <Box marginBottom={props.marginBottom ? props.marginBottom : "20px"} sx={props.sx}>
      {
        props.label && !props.biggerLabel && <Text fontSize="12px" fontWeight="600" marginBottom="7.5px">{props.label}</Text>
      } 

      {
        props.label && props.biggerLabel && <Text fontSize="16px" fontWeight="600" marginBottom="7.5px" color="#607B96" userSelect="none">{props.label}</Text>
      }
      <Input 
        ref={props.ref}
        name={props.name}
        disabled={props.loading}
        height="35px" 
        border="1px solid #fff"
        _focus={{border: "1px solid #fff", boxShadow: "none"}}
        placeholder={props.placeholder}
        borderRadius="8px"
        width="100%"
        type={props.type}
        value={props.loading ? "Loading..." : props.value}
        onKeyDown={props.onKeyDown}
        onChange={props.onChange}
      />
    </Box>
  )
}

export default InputText