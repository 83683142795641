import React, {FC} from "react"
import { Box, IconButton, Icon, SystemStyleObject } from "@chakra-ui/react"
import {IoAddSharp as AddIcon, IoPencilOutline as EditIcon, IoEyeOutline as ShowIcon} from "react-icons/io5"
import {IoTrashOutline as RemoveIcon} from "react-icons/io5"
import {AiOutlineSave as SaveIcon} from "react-icons/ai"
import {TiArrowBack as BackIcon} from "react-icons/ti"

import {TailSpin as LoaderSpinner} from "react-loader-spinner"

interface Props {
  margin?: string
  size?: "md"
  icon: "add" | "edit" | "remove" | "show" | "save" | "back"
  sx?: SystemStyleObject
  loading?: boolean
  onClick: (e:any) => void
}

const InputButtonIcon:FC<Props> = (props) => {
  return (
    <Box m={props.margin ? props.margin : "0"}>
      <IconButton aria-label={props.icon} 
        icon={props.loading ? <LoaderSpinner width="20px" height="25px" color="#000"/> : props.icon === "add" ? <ButtonIcon as={AddIcon} size={props.size}/> : props.icon === "edit" ? <ButtonIcon as={EditIcon} size={props.size}/> : props.icon === "remove" ? <ButtonIcon as={RemoveIcon} size={props.size}/> : props.icon === "show" ? <ButtonIcon as={ShowIcon} size={props.size}/> : props.icon === "save" ? <ButtonIcon as={SaveIcon} size={props.size}/> : props.icon === "back" ? <ButtonIcon as={BackIcon} size={props.size}/> : undefined} 
        backgroundColor={props.icon === "add" ? "#4DC313" : props.icon === "edit" ? "#FFC228" : props.icon === "remove" ? "#EC0D0D" : props.icon === "show" ? "#995EE4" : props.icon === "save" ? "#316CF3" : props.icon === "back" ? "#cecece" : undefined} 
        _hover={{backgroundColor: !props.loading ? (props.icon === "add" ? "#4DC313cc" : props.icon === "edit" ? "#FFC228cc" : props.icon === "remove" ? "#EC0D0Dcc" : props.icon === "show" ? "#995EE4cc" : props.icon === "save" ? "#316CF3cc" : props.icon === "back" ? "#cecececc" : undefined) : undefined}} 
        _active={{backgroundColor: !props.loading ? (props.icon === "add" ? "#4DC313aa" : props.icon === "edit" ? "#FFC228aa" : props.icon === "remove" ? "#EC0D0Daa" : props.icon === "show" ? "#995EE4aa" : props.icon === "save" ? "#316CF3aa" : props.icon === "back" ? "#cececeaa" : undefined) : undefined, transform: !props.loading ? "scale(0.95)" : undefined}} 
        borderRadius="16px" size="xs" width={props.size === "md" ? "48px" : "24px"} height="auto" style={{aspectRatio: "3/2"}} sx={props.sx} onClick={props.onClick}
        disabled={props.loading}
      />
    </Box>
  )
}

interface ButtonIconProps {
  as: any
  size?: "md" 
}

const ButtonIcon:FC<ButtonIconProps> = (props) => {
  return (
    <Icon as={props.as} fontSize={props.size === "md" ? "28px" : "12px"} color="black"/>
  )
}

export default InputButtonIcon