import React, { useEffect } from "react"
import {BrowserRouter, Routes, Route} from "react-router-dom"
import {ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// AUTH
import ProtectedRoutes from "./auth/ProtectedRoutes"
import PublicRoutes from "./auth/PublicRoutes"

// LAYOUT
import Layout from "./Components/Layout/Layout"

// PROTECTED PAGES
import PageHome from "./Pages/Home"
import PageLevels from "./Pages/Levels"
import PageLevel from "./Pages/Level"
import PageAssets from "./Pages/Assets"
import PageAsset from "./Pages/Asset"
import PageUsers from "./Pages/Users"
import PageUser from "./Pages/User"
import PageLogout from "./Pages/Logout"
import PagePassword from "./Pages/Password"

// PUBLIC PAGES
import PageLogin from "./Pages/Login"

function App() {  
  return(
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<ProtectedRoutes/>}>
            <Route path="/" element={<Layout header rightSidebar page={<PageHome/>}/>}/>
            <Route path="/levels" element={<Layout header page={<PageLevels/>}/>}/>
            <Route path="/levels/new-level" element={<Layout header leftSidebar rightSidebar page={<PageLevel/>}/>}/>
            <Route path="/levels/edit-level/:id" element={<Layout header leftSidebar rightSidebar page={<PageLevel/>}/>}/>
            <Route path="/blockers" element={<Layout header page={<PageAssets/>}/>}/>
            <Route path="/blockers/new-blocker" element={<Layout header page={<PageAsset/>}/>}/>
            <Route path="/blockers/edit-blocker" element={<Layout header page={<PageAsset/>}/>}/>
            <Route path="/spawners" element={<Layout header page={<PageAssets/>}/>}/>
            <Route path="/spawners/new-spawner" element={<Layout header page={<PageAsset/>}/>}/>
            <Route path="/spawners/edit-spawner" element={<Layout header page={<PageAsset/>}/>}/>
            <Route path="/users" element={<Layout header page={<PageUsers/>}/>}/>
            <Route path="/users/new-user" element={<Layout header page={<PageUser/>}/>}/>
            <Route path="/users/edit-user/:id" element={<Layout header page={<PageUser/>}/>}/>
            <Route path="/change-password" element={<Layout header page={<PagePassword/>}/>}/>
          </Route>

          <Route element={<PublicRoutes/>}>
            <Route path="/login" element={<PageLogin/>}/>
          </Route>

          <Route path="/logout" element={<PageLogout/>}/>
        </Routes>
      </BrowserRouter>

      <ToastContainer position="bottom-center" theme="dark" autoClose={3000} draggable={false} closeOnClick={false} newestOnTop pauseOnHover/>
    </>
  )
}

export default App
