import React, {useEffect, useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import { changeObjective01, changeObjective02, changeObjective03, changeMove, changeTime } from "../../../redux/levelSlice"
import { InterfaceAllSlices } from "../../../typescript/interfaceAllSlices"

import {Box, Text, Image} from "@chakra-ui/react"
import {motion, AnimatePresence} from "framer-motion"

import InputSelectText from "../../Inputs/InputSelectText"
import InputNumber from "../../Inputs/InputNumber"
import ImageDynamic from "../../ImageDynamic"

import spawners from "../../../assets/fakeData/spawners"
import blockers from "../../../assets/fakeData/blockers"

const Objectives = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()  

  const [objectives, setOobjectives] = useState([
    spawners.filter(spawner => spawner.type === 0 && spawner.name2 !== "randomStone").map(spawner =>
      (
        {
          name: spawner.name,
          name2: spawner.name2,
          color: spawner.name2 === "blueStone" ? "#5C9CE6" : spawner.name2 === "yellowStone" ? "#FFC107" : spawner.name2 === "pinkStone" ? "#E64646" : spawner.name2 === "purpleStone" ? "#995EE4" : spawner.name2 === "greenStone" ? "#4BB34B" : ""
        }
      )
    ),
    spawners.filter(spawner => spawner.type === 2 && spawner.biome === levelStates.biomeType.toLowerCase()).map(spawner =>
      (
        {
          name: spawner.name,
          name2: spawner.name2,
        }
      )
    ),
    blockers.filter(blocker => blocker.blockerState === 1 && (blocker.biome === levelStates.biomeType.toLowerCase() || blocker.biome === "all")).map(blocker => 
      (
        {
          name: blocker.type === "breakableBlocker" ? "Breakable Blocker" : blocker.type === "collectibleBlocker" ? "Collectible Blocker" : blocker.type === "coverBlocker" ? "Cover Blocker" : blocker.type === "humanoidBlocker" ? "Humanoid Blocker" : blocker.type === "randomHumanoidBlocker" ? "Random Humanoid Blocker" : "",
          name2: blocker.type,
        }
      )  
    )
  ])

  function onChangeObjective01(dataset:{text:string, text2:string, count: number}){
    const data = {
      type: dataset.text,
      type2: dataset.text2,
      count: dataset.count ? dataset.count : 1,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeObjective01(data))
  }

  function onChangeObjective02(dataset:{text:string, text2:string, count: number}){
    const data = {
      type: dataset.text,
      type2: dataset.text2,
      count: dataset.count ? dataset.count : 1,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeObjective02(data))
  }

  function onChangeObjective03(dataset:{text:string, text2:string, count: number}){
    const data = {
      type: dataset.text,
      type2: dataset.text2,
      count: dataset.count ? dataset.count : 1,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeObjective03(data))
  }

  function onChangeMoveObjective(val:number){
    const data = {
      moveObjective: Number(val),
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeMove(data))
  }

  function onChangeTimeObjective(val:number){
    const data = {
      timeObjective: Number(val),
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeTime(data))
  }

  return (
    <Box pt="100px" m="auto" w="320px">
      <Box mb="20px" minHeight="25px">
        {
          levelStates.objectiveDataContainer.gameMode === false ? <Text fontSize="16px" fontWeight="700" userSelect="none" margin="0 0 10px 0">Move: {levelStates.objectiveDataContainer.moveObjective}</Text> : <Text fontSize="16px" fontWeight="700" userSelect="none" margin="0 0 10px 0">Time: {levelStates.objectiveDataContainer.timeObjective}</Text>
        }
      </Box>

      <Box display="flex" flexDir="row" alignItems="center" mb="70px">
        <Box w="92px" h="92px" border="1px solid #fff" borderRadius="8px" m="0 5px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none">
          {
            levelStates.objectiveDataContainer.objective01.type !== "None" && 
            <>
              <ImageDynamic name={levelStates.objectiveDataContainer.objective01.type2} motion="scale" w="52px" h="52px"/>
              <Text fontSize="20px" fontWeight="700" pos="absolute" right="5px" bottom="5px" lineHeight="1">{levelStates.objectiveDataContainer.objective01.count}</Text>
            </>
          }
        </Box>

        <Box w="92px" h="92px" border="1px solid #fff" borderRadius="8px" m="0 5px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none">
          {
            levelStates.objectiveDataContainer.objective02.type !== "None" && 
            <>
              <ImageDynamic name={levelStates.objectiveDataContainer.objective02.type2} motion="scale" w="52px" h="52px"/>
              <Text fontSize="20px" fontWeight="700" pos="absolute" right="5px" bottom="5px" lineHeight="1">{levelStates.objectiveDataContainer.objective02.count}</Text>
            </>
          }
        </Box>

        <Box w="92px" h="92px" border="1px solid #fff" borderRadius="8px" m="0 5px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none">
          {
            levelStates.objectiveDataContainer.objective03.type !== "None" && 
            <>
              <ImageDynamic name={levelStates.objectiveDataContainer.objective03.type2} motion="scale" w="52px" h="52px"/>
              <Text fontSize="20px" fontWeight="700" pos="absolute" right="5px" bottom="5px" lineHeight="1">{levelStates.objectiveDataContainer.objective03.count}</Text>
            </>
          }
        </Box>
      </Box>

      <Box display="flex" flexDir="column">
        <Box display="flex" flexDir="row" mb="20px">
          <InputSelectText margin="0 20px 0 0" label="Objective 1" placeholder="Select..." dropdownTexts={objectives} value={levelStates.objectiveDataContainer.objective01.type === "None" ? "Select..." : levelStates.objectiveDataContainer.objective01.type} onClick={(e)=>onChangeObjective01(e.target.dataset)}/>
          <AnimatePresence>
            {
              levelStates.objectiveDataContainer.objective01.type !== "None" && <Box as={motion.div} initial={{translateX: -10, opacity: 0}} animate={{translateX: 0, opacity: 1}} exit={{opacity: 0}}><InputNumber margin='0' label="Count" value={levelStates.objectiveDataContainer.objective01.count} onChange={(val) =>  val > 0 ? onChangeObjective01({text: levelStates.objectiveDataContainer.objective01.type, text2: levelStates.objectiveDataContainer.objective01.type2, count: val}) : onChangeObjective01({text: "None", text2: "none", count: 0})}/></Box>
            }
          </AnimatePresence>
        </Box>

        <Box display="flex" flexDir="row" mb="20px">
          <InputSelectText margin="0 20px 0 0" label="Objective 2" placeholder="Select..." dropdownTexts={objectives} value={levelStates.objectiveDataContainer.objective02.type === "None" ? "Select..." : levelStates.objectiveDataContainer.objective02.type} onClick={(e)=>onChangeObjective02(e.target.dataset)}/>
          <AnimatePresence>
            {
              levelStates.objectiveDataContainer.objective02.type !== "None" && <Box as={motion.div} initial={{translateX: -10, opacity: 0}} animate={{translateX: 0, opacity: 1}} exit={{opacity: 0}}><InputNumber margin='0' label="Count" value={levelStates.objectiveDataContainer.objective02.count} onChange={(val) =>  val > 0 ? onChangeObjective02({text: levelStates.objectiveDataContainer.objective02.type, text2: levelStates.objectiveDataContainer.objective02.type2, count: val}) : onChangeObjective02({text: "None", text2: "none", count: 0})}/></Box>
            }
          </AnimatePresence>
        </Box>

        <Box display="flex" flexDir="row" mb="20px">
          <InputSelectText margin="0 20px 0 0" label="Objective 3" placeholder="Select..." dropdownTexts={objectives} value={levelStates.objectiveDataContainer.objective03.type === "None" ? "Select..." : levelStates.objectiveDataContainer.objective03.type} onClick={(e)=>onChangeObjective03(e.target.dataset)}/>
          <AnimatePresence>
            {
              levelStates.objectiveDataContainer.objective03.type !== "None" && <Box as={motion.div} initial={{translateX: -10, opacity: 0}} animate={{translateX: 0, opacity: 1}} exit={{opacity: 0}}><InputNumber margin='0' label="Count" value={levelStates.objectiveDataContainer.objective03.count} onChange={(val) =>  val > 0 ? onChangeObjective03({text: levelStates.objectiveDataContainer.objective03.type, text2: levelStates.objectiveDataContainer.objective03.type2, count: val}) : onChangeObjective03({text: "None", text2: "none", count: 0})}/></Box>
            }
          </AnimatePresence>
        </Box>

        <Box>
          {
            levelStates.objectiveDataContainer.gameMode === false ? <InputNumber label='Move' min={1} value={levelStates.objectiveDataContainer.moveObjective} onChange={(val)=>onChangeMoveObjective(Number(val))}/> : <InputNumber label='Time' min={1} value={levelStates.objectiveDataContainer.timeObjective} onChange={(val)=>onChangeTimeObjective(Number(val))}/>
          }
        </Box>
      </Box>
    </Box>
  )
}

export default Objectives