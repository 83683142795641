import React, { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "../axios"

import { useDispatch, useSelector } from "react-redux"
import { InterfaceAllSlices } from "../typescript/interfaceAllSlices"
import { InterfaceUserSlice } from "../typescript/interfaceUserSlice"

import { Box, SystemStyleObject, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import { TailSpin as LoaderSpinner } from "react-loader-spinner"
import { toast } from "react-toastify"

import InputButtonIcon from "../Components/Inputs/InputButtonIcon"
import InputSelectText from "../Components/Inputs/InputSelectText"
import InputText from "../Components/Inputs/InputText"


import InputCheckbox from "../Components/Inputs/InputCheckbox"

const filters = [
  [
    {
      name: "Name",
    },
    {
      name: "E-Mail",
    },
    {
      name: "Role",
    }
  ]
]

const Users = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userStates = useSelector((state:InterfaceAllSlices) => state.user)



  const [inputSearch, setInputSearch] = useState("")
  const [users, setUsers] = useState<InterfaceUserSlice[]>([])
  const [filteredUsers, setFilteredUsers] = useState<InterfaceUserSlice[]>(users)
  const [filter, setFilter] = useState("Name")
  const [usersLoading, setUsersLoading] = useState(false)

  const userColumns = [
    "Name",
    "Email",
    "Role",
    "Active",
    "Actions"
  ]
  
  function filterUsers(){

    const foundUsers:InterfaceUserSlice[] = []

    users.map((user)=>{

      let search:any
      
      if(filter === "Name"){
        search = user["name"].toLowerCase().search(inputSearch.toLowerCase())
      } else if (filter === "E-Mail"){
        search = user["email"].toLowerCase().search(inputSearch.toLowerCase())
      } else if (filter === "Role"){
        search = user["role"].toLowerCase().search(inputSearch.toLowerCase())
      }
      

      if(inputSearch.length > 0 && search > -1){
        foundUsers.push(user)
      }

    })

    setFilteredUsers(inputSearch.length > 0 ? foundUsers : users)

  }

  useEffect(()=>{
    filterUsers()
  },[inputSearch,filter,users])

  function getUsers(){

    setUsersLoading(true)
    
    axios("/users").then((res)=>{
      setUsersLoading(false)
      setUsers([...res.data])
      setFilteredUsers([...res.data])
    }).catch((err)=>{
      setUsersLoading(false)
      console.log(err)
    })
  }

  useEffect(()=>{
    getUsers()
  },[])

  function changeUserActivation(user:any){    
    axios.put("/users",{
      id: user.id,
      name: user.name,
      email: user.email,
      active: !user.active
    }).then((res)=>{
      toast.success(res.statusText)
      getUsers()
    }).catch((err)=>{
      toast.error(err.response.data.message)
    })
  }

  function deleteUser(id:string){
    axios.delete(`/users/${id}`).then((res)=>{
      toast.success(res.statusText)
      getUsers()
    }).catch((err)=>{
      toast.error(err.response.data.message)
    })
  }
  
  return (
    <Box pt="90px" m="auto" w="1100px">

      <Box display="flex" justifyContent="flex-end" alignItems="center" mb="20px" w="100%">
        <InputButtonIcon icon="add" size="md" onClick={()=>navigate("/users/new-user")}/>
      </Box>

      <Box pos="relative">
        <InputText type="text" value={inputSearch} placeholder="Search" onChange={(e)=>setInputSearch(e.target.value)}/>
        <Box pos="absolute" right="0" top="0" zIndex={2}>
          <InputSelectText borderBottomLeftRadius="0" borderTopLeftRadius="0" width="267px" dropdownTexts={filters} value={filter} onClick={(e)=>setFilter(e.target.dataset.text)}/>
        </Box>
      </Box>
      
      <TableContainer w="100%" m="0 auto" border="1px solid #fff" borderRadius="8px">
        <Table display="flex" flexDir="column">
          <Thead>
            <Tr display="flex">
              {
                userColumns.map((userColumn,i)=>
                  <Th key={i} color="#fff" p="10px 15px" borderRight="1px solid #fff" w={i === 2 ? "20%" : (i === 3 || i === 4) ? "12.5%" : "27.5%"} sx={{"&:last-child" : {borderRight: "0"}}}>{userColumn}</Th>
                )
              }
            </Tr>
          </Thead>

          <Tbody>
            <AnimatePresence>
              {
                filteredUsers?.sort((a,b)=>a.role > b.role ? 1 : -1).map((user,i)=>
                  <Tr key={i} as={motion.tr} display="flex"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={`${i*0.2}s`}
                  >
                    <TableData sx={{w:"27.5%"}}>{user.name}</TableData>
                    <TableData sx={{w:"27.5%"}}>{user.email}</TableData>
                    <TableData sx={{w:"20%"}}>{user.role}</TableData>
                    <TableData sx={{w:"12.5%"}}>
                      <InputCheckbox margin="auto" value={user.active} onChange={()=>changeUserActivation(user)}/>
                    </TableData>
                    <TableData sx={{w:"12.5%"}}>
                      <Box display="flex" flexDir="row" justifyContent="center">
                        <InputButtonIcon icon='edit' margin="0 10px 0 0" onClick={()=>navigate(`/users/edit-user/${user.id}`)}/>
                        <InputButtonIcon icon='remove' margin="0" onClick={()=>deleteUser(user.id)}/> 
                      </Box>
                    </TableData>
                  </Tr>
                )
              }
              {
                usersLoading &&
                <Tr as={motion.tr} display="flex" justifyContent="center" alignItems="center" w="100%" h="200px" overflow="hidden"
                  initial={{height: 0}} 
                  animate={{height: 50}} 
                  exit={{height: 0}}
                >
                  <Td as={motion.td}
                    initial={{opacity: 0, scale: 0}}
                    animate={{opacity: 1, scale: 1}} 
                    exit={{opacity: 0, scale: 0}}
                    transition="0"
                  >
                    <LoaderSpinner width="25" color="#fff"/>
                  </Td>
                </Tr>
              }
            </AnimatePresence>
          </Tbody>
              
        </Table>
      </TableContainer>
    </Box>
  )
}

interface TableDataProps {
  children: React.ReactNode
  sx?: SystemStyleObject
}

const TableData:FC<TableDataProps> = (props) => {

  return (
    <Td overflow="hidden" p="10px 15px" borderRight="1px solid #fff" fontSize="10px" fontWeight="400" sx={{"tr &:last-child": {borderRight: 0}, "tr:last-child &": {borderBottom: 0}, ...props.sx}}>
      {props.children}
    </Td>
  )
}

export default Users 