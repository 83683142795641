import React, {FC, useEffect, useState} from "react"
import { useNavigate } from "react-router-dom"
import axios from "../axios"

import { useDispatch } from "react-redux"
import { collapsePopupNewLevel } from "../redux/popupManagerSlice"
import { changeEditable, changePurpose } from "../redux/editorSlice"
import { resetLevel } from "../redux/levelSlice"

import {Box, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Text, SystemStyleObject} from "@chakra-ui/react"
import { motion, AnimatePresence } from "framer-motion"
import {TailSpin as LoaderSpinner} from "react-loader-spinner"
import { toast } from "react-toastify"

import InputButtonIcon from "../Components/Inputs/InputButtonIcon"
import InputText from "../Components/Inputs/InputText"
import InputNumber from "../Components/Inputs/InputNumber"
import InputSelectText from "../Components/Inputs/InputSelectText"

const filters = [
  [
    {
      name: "Level Number",
    },
    {
      name: "Category",
    },
    {
      name: "Description",
    },
    {
      name: "Created By",
    }
  ]
]

interface InterfaceLevelFromBackend {
  id: string
  number: number
  biomeType: string
  objectiveDataContainer: {
    gameMode: string
  }
  description: string
  userName: string
}

const Levels = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [inputSearch, setInputSearch] = useState("")
  const [levels, setLevels] = useState<InterfaceLevelFromBackend[]>([])
  const [filteredLevels, setFilteredLevels] = useState<InterfaceLevelFromBackend[]>(levels)
  const [filter, setFilter] = useState("Level Number")
  const [showFrom, setShowFrom] = useState(0)
  const [showTo, setShowTo] = useState<number>(10)
  const [pages, setPages] = useState(0)
  const [selectedPage, setSelectedPage] = useState(0)
  const [levelsLoading, setLevelsLoading] = useState(false)

  const levelColumns = [
    {id: 0, name: "Level No"},
    {id: 1, name: "Category"},
    {id: 2, name: "Description"},
    {id: 3, name: "Created By"},
    {id: 4, name: "Actions"}
  ]
  
  function filterLevels(){

    const foundLevels:InterfaceLevelFromBackend[] = []

    levels.map((level)=>{

      let search:any
      
      if(filter === "Level Number"){
        search = level.number.toString() === inputSearch ? 0 : -1
      } else if (filter === "Category"){
        search = level.biomeType.toLowerCase().search(inputSearch.toLowerCase())
      } else if (filter === "Description"){
        search = level.description.toLowerCase().search(inputSearch.toLowerCase())
      } else if (filter === "Created By"){
        search = level.userName.toLowerCase().search(inputSearch.toLowerCase())
      }
      

      if(inputSearch.length > 0 && search > -1){
        foundLevels.push(level)
      }

    })

    setFilteredLevels(inputSearch.length > 0 ? foundLevels : levels)
    calculatePages()

  }

  function calculatePages(goToLast?:boolean){
    setPages(Math.ceil(filteredLevels.length / showTo))
    setSelectedPage(0)
  }

  function changePage(){
    setShowFrom(selectedPage * showTo)
  }

  useEffect(()=>{
    calculatePages()
  },[showTo])

  useEffect(()=>{
    filterLevels()
    calculatePages()
  },[inputSearch,filter])

  useEffect(()=>{
    changePage()
  },[selectedPage])

  function getLevels(){

    setLevelsLoading(true)
    
    axios("/levels").then((res)=>{

      const levels = [
        ...res.data.map((level:InterfaceLevelFromBackend)=>({
          id: level.id,
          number: level.number,
          biomeType: `${level.biomeType} ${level.objectiveDataContainer?.gameMode ? "(Rush)" : ""}`,
          description: level.description,
          userName: level.userName
        }))
      ]
      
      setLevelsLoading(false)
      setShowTo(levels.length < 10 ? levels.length : 10)
      setLevels(levels)
      setFilteredLevels(levels)
    }).catch((err)=>{
      setLevelsLoading(false)
      console.log(err)
    })
  }

  useEffect(()=>{
    getLevels()
  },[])

  function deleteLevel(id:string){    
    axios.delete("/levels", {data: {id: id}}).then((res)=>{
      toast.success(res.statusText)
      getLevels()
    }).catch((err)=>{
      toast.error(err.response.data.message)
    })
  }
  
  return (
    <Box pt="90px" m="auto" w="1100px">

      <Box display="flex" justifyContent="flex-end" alignItems="center" mb="20px" w="100%">
        <InputNumber divWidth="80px" margin="0 10px 0 0" min={1} max={filteredLevels.length} value={showTo} onChange={(val:number)=>setShowTo(Number(val))}/>
        <InputButtonIcon icon="add" size="md" onClick={()=>dispatch(collapsePopupNewLevel(true))}/>
      </Box>

      <Box pos="relative">
        <InputText type="text" value={inputSearch} placeholder="Search" onChange={(e)=>setInputSearch(e.target.value)}/>
        <Box pos="absolute" right="0" top="0" zIndex={2}>
          <InputSelectText borderBottomLeftRadius="0" borderTopLeftRadius="0" width="267px" dropdownTexts={filters} value={filter} onClick={(e)=>setFilter(e.target.dataset.text)}/>
        </Box>
      </Box>
      
      <TableContainer w="100%" m="0 auto" border="1px solid #fff" borderRadius="8px">
        <Table display="flex" flexDir="column">
          <Thead>
            <Tr display="flex">
              {
                levelColumns.map((levelColumn,i)=>
                  <Th key={i} color="#fff" borderRight="1px solid #fff" w={i === 0 ? "10%" : i === 1 ? "15%" : i === 2 ? "45%" : i === 3 ? "15%" : i === 4 ? "15%" : ""} maxW="500px" sx={{"&:last-child" : {borderRight: "0"}}}>{levelColumn.name}</Th>
                )
              }
            </Tr>
          </Thead>

          <Tbody>
            <AnimatePresence>
              {
                filteredLevels.slice(showFrom, showFrom + showTo).map((level,i)=>
                  <Tr key={i} as={motion.tr} display="flex" w="100%"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={`${i*0.2}s`}
                  >
                    <TableData sx={{w:"10%"}}>{level.number}</TableData>
                    <TableData sx={{w:"15%"}}>{level.biomeType}</TableData>
                    <TableData sx={{w:"45%"}}>{level.description}</TableData>
                    <TableData sx={{w:"15%"}}>{level.userName}</TableData>
                    <TableData sx={{w:"15%"}}>
                      <Box display="flex" flexDir="row" justifyContent="center">
                        <InputButtonIcon icon='edit' margin="0 10px 0 0" onClick={()=>{
                          dispatch(changePurpose("edit"))
                          dispatch(changeEditable(false))
                          dispatch(resetLevel())
                          navigate(`/levels/edit-level/${level.id}`)
                        }}/>
                        <InputButtonIcon icon='remove' margin="0" onClick={()=>deleteLevel(level.id)}/> 
                      </Box>
                    </TableData>
                  </Tr>
                )
              }
              {
                levelsLoading &&
                <Tr as={motion.tr} display="flex" justifyContent="center" alignItems="center" w="100%" h="200px" overflow="hidden"
                  initial={{height: 0}} 
                  animate={{height: 50}} 
                  exit={{height: 0}}
                >
                  <Td as={motion.td}
                    initial={{opacity: 0, scale: 0}}
                    animate={{opacity: 1, scale: 1}} 
                    exit={{opacity: 0, scale: 0}}
                    transition="0"
                  >
                    <LoaderSpinner width="25" color="#fff"/>
                  </Td>
                </Tr>
              }
            </AnimatePresence>
          </Tbody>
        </Table>
      </TableContainer>

      <Box display="flex" mt="10px">
        { pages > 0 &&
          [...Array(pages)].map((x,i)=>
            <Box key={i} w="25px" h="25px" mr="5px" bg={selectedPage === i ? "#fff" : "transparent"} border="1px solid #fff" borderRadius="4px" display="flex" alignItems="center" justifyContent="center" cursor="pointer" _hover={{bg: "#fff", "& p": {color: "#000"}}} onClick={()=>setSelectedPage(i)}>
              <Text color={selectedPage === i ? "#000" : "#fff"} fontSize="12px" pointerEvents="none" userSelect="none">{i+1}</Text>
            </Box>
          )
        }
      </Box>
    </Box>
  )
}

interface TableDataProps {
  children: React.ReactNode
  sx?: SystemStyleObject
}

const TableData:FC<TableDataProps> = (props) => {

  return (
    <Td overflow="hidden" p="10px 15px" borderRight="1px solid #fff" fontSize="10px" fontWeight="400" sx={{"tr &:last-child": {borderRight: 0}, "tr:last-child &": {borderBottom: 0}, ...props.sx}}>
      {props.children}
    </Td>
  )
}

export default Levels