import React, { FC } from "react"
import {TailSpin as LoaderSpinner} from "react-loader-spinner"
import { Box, Button } from "@chakra-ui/react"


interface Props {
  width?: string
  margin?: string
  bg?: string
  hoverBg?: string
  hoverColor?: string
  border?: string
  loading?: boolean
  label: string
  onClick: (e:any) => void
}

const InputButton:FC<Props> = (props) => {
  return (
    <Box margin={props.margin ? props.margin : "0 0 20px 0"} display="flex" w={props.width ? props.width : "175px"} h="42.5px">
      <Button
        bg={props.bg ? props.bg : "#607B96"} w="100%" h="100%" 
        _hover={{backgroundColor: props.hoverBg && !props.loading ? props.hoverBg : props.bg ? props.bg + "cc" : !props.loading ? "#607B96cc" : undefined, color: props.hoverColor ? props.hoverColor : ""}} 
        _active={{backgroundColor: props.bg && !props.loading ? props.bg + "aa" : !props.loading ? "#607B96aa" : undefined, transform: !props.loading ? "scale(0.95)" : undefined}} 
        borderRadius="12px" onClick={props.onClick}
        disabled={props.loading}
      >
        {
          props.loading ? <LoaderSpinner width="25px" height="25px" color="#fff"/> : props.label
        }
      </Button>
    </Box>
  )
}

export default InputButton