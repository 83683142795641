import React, { FC } from "react"
import { Box, Checkbox, Text, SystemStyleObject } from "@chakra-ui/react"

interface Props {
  margin?: string
  color?: string,
  sx?: SystemStyleObject
  label?: string[],
  value: boolean,
  onChange: (e:any) => void
}

const InputCheckbox:FC<Props> = (props) => {
  return (
    <Box margin={props.margin ? props.margin : "0 0 10px 0"} display="flex" w="fit-content">
      <Checkbox colorScheme="tranparent" borderColor={props.value === true ? props.color : "#fff"} size="lg" outline="none" backgroundColor="transparent" iconColor={props.color} color={props.color} _active={{transform: "scale(.95)"}} transition="200ms" isChecked={props.value} onChange={props.onChange}>
        <Box display="flex">
          {props.label?.map((labelItem,i)=>
            <Text key={i} marginRight="5px" color={i === 1 ? props.color : "#fff"} userSelect="none">{labelItem}</Text>
          )}
        </Box>
      </Checkbox>
    </Box>
  )
}

export default InputCheckbox