import React, {useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import { changeAlmight } from "../../../redux/levelSlice"
import { InterfaceAllSlices } from "../../../typescript/interfaceAllSlices"

import {Box} from "@chakra-ui/react"
import InputSelectText from "../../Inputs/InputSelectText"

import powerUps, {powerUps2} from "../../../assets/fakeData/powerUps.js"

const PowerUps = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()

  function onChangeRewards({almightCharge, almight}:{almightCharge:string, almight:string}){
    const data = {
      almightCharge: almightCharge,
      almight: almight,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeAlmight(data))
  }

  return (
    <Box pt="100px" mx="auto" w="fit-content">
      <InputSelectText dropdownTexts={powerUps} label="Almight Charge" placeholder="Select" value={levelStates.almight.almightCharge === "None" ? "Select..." : levelStates.almight.almightCharge} onClick={(e)=>onChangeRewards({almightCharge: e.target.dataset.text, almight: levelStates.almight.almight})}/>
      {
        levelStates.almight.almightCharge === "Continues" && <InputSelectText dropdownTexts={powerUps2} label="Almight" placeholder="Select" value={levelStates.almight.almight === "None" ? "Select..." : levelStates.almight.almight} onClick={(e)=>onChangeRewards({almightCharge: levelStates.almight.almightCharge, almight: e.target.dataset.text})}/>
      }
    </Box>
  )
}

export default PowerUps