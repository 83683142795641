import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { changeRandomStoneList } from "../../../redux/levelSlice"
import { InterfaceAllSlices } from "../../../typescript/interfaceAllSlices"

import {Box} from "@chakra-ui/react"
import InputCheckbox from "../../Inputs/InputCheckbox"

const colors = [
  "#5C9CE6", "#FFC107", "#E64646", "#995EE4", "#4BB34B"
]

interface Gradient {
  color: string
  persentage: string
}

const RandomStoneList = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()
  
  const [gradient, setGradient] = useState<Gradient[]>([])

  function calculateGradient(){

    const checkboxes = [levelStates.randomStoneList.hasBlueRatio, levelStates.randomStoneList.hasYellowRatio, levelStates.randomStoneList.hasPinkRatio, levelStates.randomStoneList.hasPurpleRatio, levelStates.randomStoneList.hasGreenRatio]
    const checkedCheckboxes = []

    for (let i = 0; i < checkboxes.length; i++) {
      if(checkboxes[i] === true){
        checkedCheckboxes.push({color: colors[i], persentage: ""})
      }
    }

    for (let i = 0; i < checkedCheckboxes.length; i++) {

      const persentage:number = 100 / checkedCheckboxes.length
      
      checkedCheckboxes[i] = {
        color: checkedCheckboxes[i].color,
        persentage: persentage * (i+1) + "%"
      }
    }

    setGradient(checkedCheckboxes)
    
  }

  useEffect(()=>{
    calculateGradient()    
  },[levelStates.randomStoneList])

  function onChangeRandomStoneList(color:string,value:boolean){
    const data = {
      ...levelStates.randomStoneList,
      [color]: value,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }

    dispatch(changeRandomStoneList(data))
  }
  
  return (
    <Box pt="100px" mx="auto" w="fit-content">
      <Box w="92px" h="92px" border="1px solid #fff" borderRadius="8px" display="flex" justifyContent="center" alignItems="center" m="0 auto 70px auto">
        <Box w="60px" h="60px" borderRadius="50%" sx={{bg: `radial-gradient(circle closest-side, transparent 100%,white 0),conic-gradient(
          ${gradient.map((gradientItem)=>`${gradientItem.color} 0%, ${gradientItem.color} ${gradientItem.persentage}`)})`}}/>
      </Box>

      <InputCheckbox color={colors[0]} label={["Has", "Blue", "Stone"]} value={levelStates.randomStoneList.hasBlueRatio} onChange={(e)=>onChangeRandomStoneList("hasBlueRatio", e.target.checked)}/>
      <InputCheckbox color={colors[1]} label={["Has", "Yellow", "Stone"]} value={levelStates.randomStoneList.hasYellowRatio} onChange={(e)=>onChangeRandomStoneList("hasYellowRatio", e.target.checked)}/>
      <InputCheckbox color={colors[2]} label={["Has", "Pink", "Stone"]} value={levelStates.randomStoneList.hasPinkRatio} onChange={(e)=>onChangeRandomStoneList("hasPinkRatio", e.target.checked)}/>
      <InputCheckbox color={colors[3]} label={["Has", "Purple", "Stone"]} value={levelStates.randomStoneList.hasPurpleRatio} onChange={(e)=>onChangeRandomStoneList("hasPurpleRatio", e.target.checked)}/>
      <InputCheckbox color={colors[4]} label={["Has", "Green", "Stone"]} value={levelStates.randomStoneList.hasGreenRatio} onChange={(e)=>onChangeRandomStoneList("hasGreenRatio", e.target.checked)}/>
    </Box>
  )
}

export default RandomStoneList