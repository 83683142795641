import React, {useState, useEffect} from "react"
import { useSelector, useDispatch } from "react-redux"
import { changeClipboard } from "../../../redux/editorSlice"
import { InterfaceSpawner } from "../../../typescript/interfaceSpawner"
import { InterfaceAllSlices } from "../../../typescript/interfaceAllSlices"

import {Box} from "@chakra-ui/react"

import InputSelectItem from "../../Inputs/InputSelectItem"

import spawners from "../../../assets/fakeData/spawners"

const PlayableItems = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const dispatch = useDispatch()

  const [colorStones, setColorStones] = useState<InterfaceSpawner[]>([])
  const [powerStones, setPowerStones] = useState<InterfaceSpawner[]>([])
  const [droppables, setDroppables] = useState<InterfaceSpawner[]>([])

  function getPlayableItems(){
    const localColorStones:InterfaceSpawner[] = []
    const localPowerStones:InterfaceSpawner[] = []
    const localDroppables:InterfaceSpawner[] = []
    
    spawners.map((spawner,i)=>{

      if(spawner.type === 0){
        localColorStones.push(spawner)
      } else if(spawner.type === 1){
        localPowerStones.push(spawner)
      } else if(spawner.type === 2 && spawner.biome === levelStates.biomeType.toLowerCase()){
        localDroppables.push(spawner)
      }
      
    })

    setColorStones(localColorStones)
    setPowerStones(localPowerStones)
    setDroppables(localDroppables)
  }

  useEffect(()=>{
    getPlayableItems()
  },[])
  
  return (
    <Box pt="100px" mx="auto" w="fit-content" display="flex" flexDir="column">
      <InputSelectItem placeholder="Color Stones" dropdownItemsGrid={3} dropdownItems={colorStones} onClick={(e)=>dispatch(changeClipboard({target: 1, type: e.target.dataset.text, type2: e.target.dataset.text2}))}/>
      <InputSelectItem placeholder="Power Stones" dropdownItemsGrid={3} dropdownItems={powerStones} onClick={(e)=>dispatch(changeClipboard({target: 1, type: e.target.dataset.text, type2: e.target.dataset.text2}))}/>
      <InputSelectItem placeholder="Droppable Stones" dropdownItemsGrid={3} dropdownItems={droppables} onClick={(e)=>dispatch(changeClipboard({target: 1, type: e.target.dataset.text, type2: e.target.dataset.text2}))}/>
    </Box>
  )
}

export default PlayableItems