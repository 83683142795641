import React, { FC} from "react"

import { useDispatch } from "react-redux"
import { collapsePopupNewLevel } from "../redux/popupManagerSlice"
import { InterfaceNotification } from "../typescript/interfaceNotification"

import {Box, Heading} from "@chakra-ui/react"

import LevelCard from "../Components/NotificationCard"
import InputButton from "../Components/Inputs/InputButton"

const Home:FC = () => {

  const dispatch = useDispatch()

  const lastLevelData:InterfaceNotification = {
    message: "Yusuf Akbaba created a level",
    level: {
      id: "sdf",
      number: 1,
      biome: "Forest",
      gameMode: "Rush",
      createdBy: "Tunc Angin",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
      lastMessage: {
        name: "Alp Koçak",
        message: "Lorem ipsum dolor sit amet."
      }
    }
    
  }
  
  return (
    <Box width="670px" m="90px auto 0 auto">
      <Heading as="h3" color="#3E3E3E" fontSize="12px" fontWeight="400" marginBottom="5px">09.08.2022 Tuesday</Heading>
      <Heading as="h1" fontSize="20px" fontWeight="600" marginBottom="20px">Last Edited Level</Heading>
      <LevelCard levelData={lastLevelData} showLevel showButtonEdit showBiome showCreatedBy showDescription showMessage/>
      <InputButton label='New Level' margin='315px auto 0 auto' onClick={()=>dispatch(collapsePopupNewLevel(true))}/>
    </Box>
  )
}

export default Home