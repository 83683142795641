import React, { FC, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { changeDescription } from "../../../redux/levelSlice"
import { InterfaceAllSlices } from "../../../typescript/interfaceAllSlices"

import {Box, Accordion, AccordionItem, AccordionButton, AccordionPanel} from "@chakra-ui/react"
import Chat from "../../Chat"
import InputTextarea from "../../Inputs/InputTextarea"

const DescriptionAndComments:FC = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()

  function onChangeDescription(text:string){
    const data = {
      description: text,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeDescription(data))
  }
  
  return (
    <Box pt="100px">
      <Accordion allowMultiple defaultIndex={[0,1]} w="400px" m="auto">
        <AccordionItem border="0" mb="20px">
          <AccordionButton border="1px solid #fff" borderRadius="8px">Description</AccordionButton>

          <AccordionPanel p="10px 0 0 0">
            <InputTextarea placeholder="Type something about the level." width="100%" value={levelStates.description} onChange={(e)=>onChangeDescription(e.target.value)}/>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem border="0">
          <AccordionButton border="1px solid #fff" borderRadius="8px">Chat</AccordionButton>

          <AccordionPanel p="10px 0 0 0">
            <Chat/>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>  
  )
}

export default DescriptionAndComments