import React, { useEffect } from "react"
import { Outlet, Navigate, useLocation } from "react-router-dom"

import { useSelector } from "react-redux"
import { InterfaceAllSlices } from "../typescript/interfaceAllSlices"

const ProtectedRoutes = () => {

  const location = useLocation()
  const userStates = useSelector((state:InterfaceAllSlices) => state.user)
  
  const now = Date.now().toString()
  const now10 = Number(now.slice(0,10))
  
  function verify(){
    
    if(now10 < userStates.exp){
      return true
    } else {
      return false
    }
  }

  useEffect(()=>{
    verify()
  },[location.pathname])

  return (
    verify() ? <Outlet/> : <Navigate to="/logout"/>
  )
}

export default ProtectedRoutes