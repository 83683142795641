import randomStone from "../images/spawners/random.png"
import blueStone from "../images/spawners/colorStones/blue.png"
import yellowStone from "../images/spawners/colorStones/yellow.png"
import pinkStone from "../images/spawners/colorStones/pink.png"
import purpleStone from "../images/spawners/colorStones/purple.png"
import greenStone from "../images/spawners/colorStones/green.png"

import beamH from "../images/spawners/powerStones/beamH.png"
import beamV from "../images/spawners/powerStones/beamV.png"
import vortex from "../images/spawners/powerStones/vortex.png"
import snitch from "../images/spawners/powerStones/snitch.png"
import plasma from "../images/spawners/powerStones/plasma.png"

import bison from "../images/spawners/droppables/plain/bison.png"
import cow from "../images/spawners/droppables/plain/cow.png"
import ferret from "../images/spawners/droppables/plain/ferret.png"
import gazelle from "../images/spawners/droppables/plain/gazelle.png"
import goatPlain from "../images/spawners/droppables/plain/goat.png"
import pig from "../images/spawners/droppables/plain/pig.png"
import rabbit from "../images/spawners/droppables/plain/rabbit.png"
import sheep from "../images/spawners/droppables/plain/sheep.png"
import wildBoar from "../images/spawners/droppables/plain/wildBoar.png"
import wolf from "../images/spawners/droppables/plain/wolf.png"

import bear from "../images/spawners/droppables/forest/bear.png"
import capybara from "../images/spawners/droppables/forest/capybara.png"
import deer from "../images/spawners/droppables/forest/deer.png"
import frog from "../images/spawners/droppables/forest/frog.png"
import gorilla from "../images/spawners/droppables/forest/gorilla.png"
import hedgehog from "../images/spawners/droppables/forest/hedgehog.png"
import moose from "../images/spawners/droppables/forest/moose.png"
import mouse from "../images/spawners/droppables/forest/mouse.png"
import owl from "../images/spawners/droppables/forest/owl.png"
import tiger from "../images/spawners/droppables/forest/tiger.png"

import calacal from "../images/spawners/droppables/mountain/calacal.png"
import eagle from "../images/spawners/droppables/mountain/eagle.png"
import coyote from "../images/spawners/droppables/mountain/coyote.png"
import goatMountain from "../images/spawners/droppables/mountain/goat.png"
import guineaPig from "../images/spawners/droppables/mountain/guineaPig.png"
import ilipika from "../images/spawners/droppables/mountain/ilipika.png"
import leopard from "../images/spawners/droppables/mountain/leopard.png"
import llama from "../images/spawners/droppables/mountain/llama.png"
import redFox from "../images/spawners/droppables/mountain/redFox.png"
import wildSheep from "../images/spawners/droppables/mountain/wildSheep.png"

export default [
  {
    id: 0,
    name: "Random Stone",
    name2: "randomStone",
    type: 0,
    biome: "all",
    image: randomStone
  },
  {
    id: 0,
    name: "Blue Stone",
    name2: "blueStone",
    type: 0,
    biome: "all",
    image: blueStone
  },
  {
    id: 0,
    name: "Yellow Stone",
    name2: "yellowStone",
    type: 0,
    biome: "all",
    image: yellowStone
  },
  {
    id: 0,
    name: "Pink Stone",
    name2: "pinkStone",
    type: 0,
    biome: "all",
    image: pinkStone
  },
  {
    id: 0,
    name: "Purple Stone",
    name2: "purpleStone",
    type: 0,
    biome: "all",
    image: purpleStone
  },
  {
    id: 0,
    name: "Green Stone",
    name2: "greenStone",
    type: 0,
    biome: "all",
    image: greenStone
  },
  {
    id: 0,
    name: "BeamH",
    name2: "beamH",
    type: 1,
    biome: "all",
    image: beamH
  },
  {
    id: 0,
    name: "BeamV",
    name2: "beamV",
    type: 1,
    biome: "all",
    image: beamV
  },
  {
    id: 0,
    name: "Vortex",
    name2: "vortex",
    type: 1,
    biome: "all",
    image: vortex
  },
  {
    id: 0,
    name: "Snitch",
    name2: "snitch",
    type: 1,
    biome: "all",
    image: snitch
  },
  {
    id: 0,
    name: "Plasma",
    name2: "plasma",
    type: 1,
    biome: "all",
    image: plasma
  },
  {
    id: 0,
    name: "Bison",
    name2: "bison",
    type: 2,
    biome: "plain",
    image: bison
  },
  {
    id: 0,
    name: "Cow",
    name2: "cow",
    type: 2,
    biome: "plain",
    image: cow
  },
  {
    id: 0,
    name: "Ferret",
    name2: "ferret",
    type: 2,
    biome: "plain",
    image: ferret
  },
  {
    id: 0,
    name: "Gazelle",
    name2: "gazelle",
    type: 2,
    biome: "plain",
    image: gazelle
  },
  {
    id: 0,
    name: "Goat",
    name2: "goat",
    type: 2,
    biome: "plain",
    image: goatPlain
  },
  {
    id: 0,
    name: "Pig",
    name2: "pig",
    type: 2,
    biome: "plain",
    image: pig
  },
  {
    id: 0,
    name: "Rabbit",
    name2: "rabbit",
    type: 2,
    biome: "plain",
    image: rabbit
  },
  {
    id: 0,
    name: "Sheep",
    name2: "sheep",
    type: 2,
    biome: "plain",
    image: sheep
  },
  {
    id: 0,
    name: "Wild Boar",
    name2: "wildBoar",
    type: 2,
    biome: "plain",
    image: wildBoar
  },
  {
    id: 0,
    name: "Wolf",
    name2: "wolf",
    type: 2,
    biome: "plain",
    image: wolf
  },
  {
    id: 0,
    name: "Bear",
    name2: "bear",
    type: 2,
    biome: "forest",
    image: bear
  },
  {
    id: 0,
    name: "Gorilla",
    name2: "gorilla",
    type: 2,
    biome: "forest",
    image: gorilla
  },
  {
    id: 0,
    name: "Capybara",
    name2: "capybara",
    type: 2,
    biome: "forest",
    image: capybara
  },
  {
    id: 0,
    name: "Deer",
    name2: "deer",
    type: 2,
    biome: "forest",
    image: deer
  },
  {
    id: 0,
    name: "Frog",
    name2: "frog",
    type: 2,
    biome: "forest",
    image: frog
  },
  {
    id: 0,
    name: "Hedgehog",
    name2: "hedgehog",
    type: 2,
    biome: "forest",
    image: hedgehog
  },
  {
    id: 0,
    name: "Moose",
    name2: "moose",
    type: 2,
    biome: "forest",
    image: moose
  },
  {
    id: 0,
    name: "Owl",
    name2: "owl",
    type: 2,
    biome: "forest",
    image: owl
  },
  {
    id: 0,
    name: "Mouse",
    name2: "mouse",
    type: 2,
    biome: "forest",
    image: mouse
  },
  {
    id: 0,
    name: "Tiger",
    name2: "tiger",
    type: 2,
    biome: "forest",
    image: tiger
  },
  {
    id: 0,
    name: "Calacal",
    name2: "calacal",
    type: 2,
    biome: "mountain",
    image: calacal
  },
  {
    id: 0,
    name: "Eagle",
    name2: "eagle",
    type: 2,
    biome: "mountain",
    image: eagle
  },
  {
    id: 0,
    name: "Coyote",
    name2: "coyote",
    type: 2,
    biome: "mountain",
    image: coyote
  },
  {
    id: 0,
    name: "Goat Mountain",
    name2: "goatMountain",
    type: 2,
    biome: "mountain",
    image: goatMountain
  },
  {
    id: 0,
    name: "Guinea Pig",
    name2: "guineaPig",
    type: 2,
    biome: "mountain",
    image: guineaPig
  },
  {
    id: 0,
    name: "Ilipika",
    name2: "ilipika",
    type: 2,
    biome: "mountain",
    image: ilipika
  },
  {
    id: 0,
    name: "Leopard",
    name2: "leopard",
    type: 2,
    biome: "mountain",
    image: leopard
  },
  {
    id: 0,
    name: "Llama",
    name2: "llama",
    type: 2,
    biome: "mountain",
    image: llama
  },
  {
    id: 0,
    name: "Red Fox",
    name2: "redFox",
    type: 2,
    biome: "mountain",
    image: redFox
  },
  {
    id: 0,
    name: "Wild Sheep",
    name2: "wildSheep",
    type: 2,
    biome: "mountain",
    image: wildSheep
  }
  
]
