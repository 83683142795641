import axios from "axios"

function getToken(){
  const allStates = localStorage.getItem("persist:allRedux")

  if(allStates){ 
    const parsedAllStates = JSON.parse(allStates)
    const parsedUser = JSON.parse(parsedAllStates.user)
    return parsedUser.token
  } else{
    return null
  }
}

export default axios.create({
  baseURL: "https://editor-api.nora.lostctrlbackendservices.com/dev",
  headers: {
    Authorization: `Bearer ${getToken()}`
  }
})