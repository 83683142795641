import React, {useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import { changeRewards } from "../../../redux/levelSlice"
import { InterfaceAllSlices } from "../../../typescript/interfaceAllSlices"

import {Box} from "@chakra-ui/react"
import InputNumber from "../../Inputs/InputNumber"

const Rewards = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()

  function onChangeRewards({gold, experience}:{gold:number, experience:number}){
    const data = {
      gold: gold,
      experience: experience,
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeRewards(data))
  }
  
  return (
    <Box pt="100px" mx="auto" w="fit-content" display="flex">
      <InputNumber margin="0 40px 0 0" label="Materia" min={0} value={levelStates.gold} onChange={(val)=>onChangeRewards({gold: Number(val), experience: levelStates.experience})}/>
      <InputNumber label="Experience" min={0} value={levelStates.experience} onChange={(val)=>onChangeRewards({gold: levelStates.gold, experience: Number(val)})}/>
    </Box>
  )
}

export default Rewards