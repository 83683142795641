import React, { FC, useState, useEffect } from "react"
import {Box, Text, Icon, Divider} from "@chakra-ui/react"
import {BsChevronDown} from "react-icons/bs"
import {motion} from "framer-motion"

interface Props {
  loading?: boolean
  label?: string
  placeholder?: string
  margin?: string
  width?: string
  borderTopLeftRadius?: string
  borderBottomLeftRadius?: string
  dropdownTexts: {name: string, name2?: string, color?: string}[][],
  value: string
  onClick: ((e: any) => void) | undefined
}

const InputSelectText:FC<Props> = (props:Props) => {

  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  useEffect(()=>{
    if(showDropdown === true){
      setShowDropdown(false)
    }
  }, [props.value])
  
  return (
    <Box position="relative" width={props.width ? props.width : "190px"} margin={props.margin ? props.margin : "0 0 20px 0"}>
      {
        props.label &&
        <Text
          fontSize="16px"
          fontWeight="600"
          marginBottom="7.5px"
          color="#607B96"
          userSelect="none"
        >{props.label}</Text>
      }

      <Box display="flex" alignItems="center" padding="0 10px" position="relative" overflow="hidden" width="100%" height="35px" border="1px solid" borderColor="#fff" borderRadius="8px" opacity={props.loading ? 0.4 : 1} cursor={props.loading ? "not-allowed" : "pointer"} borderTopLeftRadius={props.borderTopLeftRadius} borderBottomLeftRadius={props.borderBottomLeftRadius} onClick={()=> !props.loading && setShowDropdown(!showDropdown)}>
        <Box pos="absolute" left="0" top="0" w="100%" h="100%" boxShadow="inset -60px 0 25px -20px #171717" zIndex={2}/>
        <Text userSelect="none" whiteSpace="nowrap" >{props.loading ? "Loading..." : props.value ? props.value : props.placeholder}</Text>
        <Icon as={BsChevronDown} position="absolute" right="10px" top="50%" transform="translateY(-50%)" zIndex={3} style={{transform: showDropdown ? "rotate(180deg) translateY(50%)" : ""}} transition="200ms"/>
      </Box>

      {
        showDropdown &&
        <>
          <Box as={motion.div} initial={{opacity: 0}} animate={{opacity: 1}} backgroundColor="#000000aa" position="fixed" top="0" left="0" width="100vw" height="100vh" zIndex="3" onClick={()=>setShowDropdown(false)}/>
          <Box as={motion.div} initial={{translateY: 20}} animate={{translateY: 0}} position="absolute" top={props.label ? "75px" : "45px"} zIndex="4" backgroundColor="#fff" width="100%" borderRadius="10px" textAlign="center" overflow="hidden" userSelect="none">
            {
              props.dropdownTexts.map((textGroups,i)=>
                <Box key={i}>
                  {
                    textGroups.map((textGroup,k)=>
                      <Box key={k}>
                        <DropdownText color={textGroup.color} text={textGroup.name} text2={textGroup.name2} onClick={props.onClick}/>
                      </Box>
                    )
                  }
                  {
                    props.dropdownTexts.length !== i+1 ? <Divider borderColor="#607B96" width="16px" margin="auto"/> : ""
                  }
                </Box>
              )
            }
          </Box>
        </>
      }
    </Box>
  )
}

interface DropdownTextProps {
  text?: string
  text2?: string
  color?: string,
  onClick: ((e:any) => void) | undefined
}

const DropdownText:FC<DropdownTextProps> = (props) => {
  return (
    <Text color={props.color ? props.color : "#2C3131"} fontWeight="700" fontSize="14px" padding="10px" cursor="pointer" _hover={{bg: "#CCE3E7"}} data-text={props.text} data-text2={props.text2} onClick={props.onClick}>{props.text}</Text>
  )
}

export default InputSelectText