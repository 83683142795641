import React, { FC } from "react"
import { Box } from "@chakra-ui/react"
import "./style.css"

interface Props {
  activeLabel: string
  deactiveLabel: string,
  value: boolean,
  onChange: (e: any) => void
}

const InputSwitch:FC<Props> = (props:Props) => {
  return (
    <Box marginBottom="20px">
      <label className='switch'>
        <input type="checkbox" checked={props.value} onChange={props.onChange}/>
        <span className='slider'/>
        <p className='label activeLabel'>{props.activeLabel}</p>
        <p className='label deactiveLabel'>{props.deactiveLabel}</p>
      </label>
    </Box>
  )
}

export default InputSwitch