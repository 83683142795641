import React, { FC } from "react"
import {Box, Text, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Icon} from "@chakra-ui/react"
import {BsChevronUp as UpIcon, BsChevronDown as DownIcon} from "react-icons/bs"

interface Props {
  margin?: string
  min?: number
  max?: number
  warning?: string
  inputWidth?: string
  divWidth?: string
  label?: string
  loading?: boolean
  value: number
  onChange: ((e:any) => void) | undefined
}

const InputNumber:FC<Props> = (props) => {
  return (
    <Box margin={props.margin ? props.margin : "0 0 20px 0"} w={props.divWidth ? props.divWidth : "110px"} display="flex" flexDir="column">
      {
        props.label &&
        <Text
          fontSize="16px"
          fontWeight="600"
          marginBottom="7.5px"
          color="#607B96"
          userSelect="none"
          display="inline-block"
          whiteSpace="nowrap"
        >{props.label}</Text>
      }
      <NumberInput w={props.inputWidth ? props.inputWidth : "100%"} focusBorderColor="#fff" opacity={props.loading ? 0.4 : 1} cursor={props.loading ? "notAllowed" : ""} outline="none" min={props.min} max={props.max} value={props.loading ? "Loading..." : props.value} isDisabled={props.loading} onChange={props.onChange}>
        <NumberInputField height="35px" outline="none" _focus={{boxShadow: "none"}} textAlign="center"/>
        <NumberInputStepper>
          <NumberIncrementStepper _active={{backgroundColor: "transparent"}} className="stepperButton">
            <Icon as={UpIcon} sx={{
              ".stepperButton:active &": {
                transform: "scale(.9)"
              }
            }}/>
          </NumberIncrementStepper>
          <NumberDecrementStepper _active={{backgroundColor: "transparent"}} className="stepperButton">
            <Icon as={DownIcon} sx={{
              ".stepperButton:active &": {
                transform: "scale(.9)"
              }
            }}/>
          </NumberDecrementStepper>
        </NumberInputStepper>
      </NumberInput>

      {
        props.warning && 
        <Box pt="8px">
          <Text fontSize="12px" fontWeight="300" color="#707070">{props.warning}</Text>
        </Box>
      }
    </Box>
  )
}

export default InputNumber