import React from "react"
import { Navigate } from "react-router-dom"

import { useDispatch } from "react-redux"
import { logout } from "../redux/userSlice"

const Logout = () => {

  const dispatch = useDispatch()
  dispatch(logout())
  
  return (
    <Navigate to="/login"/>
  )
}

export default Logout