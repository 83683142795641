import React, { FC } from "react"
import { Box, Text, Textarea } from "@chakra-ui/react"

interface Props {
  width?: string
  label?: string
  placeholder?: string
  margin?: string
  height?: string
  minHeight?: string
  maxHeight?: string
  value: string
  onChange: (e: any) => void
}

const InputTextarea:FC<Props> = (props:Props) => {
  return (
    <Box margin={props.margin ? props.margin : "0 0 20px 0"}>
      {
        props.label &&
        <Text
          fontSize="12px"
          fontWeight="600"
          marginBottom="7.5px"
        >{props.label}</Text>
      }
      <Textarea 
        height={props.height ? props.height : "90px"}
        minHeight={props.minHeight ? props.minHeight : "60px"}
        maxHeight={props.maxHeight ? props.maxHeight : "150px"}
        border="1px solid #fff"
        _focus={{border: "1px solid #fff", boxShadow: "none"}}
        placeholder={props.placeholder}
        width={props.width}
        value={props.value}
        onChange={props.onChange}
      />
    </Box>
  )
}

export default InputTextarea