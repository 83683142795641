import React, { FC, useState } from "react"
import {Box, Text, Icon, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark} from "@chakra-ui/react"
import {HiOutlineInformationCircle} from "react-icons/hi"

interface Props {
  borderLeft?: boolean
  value: number,
  onChange: (value: number) => void
}

const InputSliderVertical:FC<Props> = (props:Props) => {

  const [showToolkit, setShowToolkit] = useState(false)
  
  const toolkits = [
    [
      {text:"teleport"},
      {text:"conveyors"},
      {text:"walls"},
    ],
    [
      {text:"teleport"},
      {text:"conveyors"},
      {text:"walls"},
      {text:"stones"},
    ],
    [
      {text:"teleport"},
      {text:"conveyors"},
      {text:"walls"},
      {text:"stones"},
      {text:"collectibles"},
      {text:"blockers"},
    ]
  ]

  return (
    <Box position="relative" border="1px solid #1E2D3D" borderLeft={props.borderLeft === false ?  "0" : "1px solid #1E2D3D"} w="40px" h="200px" padding="13px 0px" display="flex" flexDir="column" alignItems="center" borderTopRightRadius="3px">

      {
        showToolkit &&  
        toolkits.map((toolkit,i)=>
          <Box key={i} display={props.value === i ? "block" : "none"} position="absolute" left="50px" top="0" backgroundColor="#171717" zIndex={2} border="1px solid #1E2D3D" borderRadius="4px" padding="15px 0" textAlign="center" w="110px">
            {
              toolkit.map((toolkitItem,i)=>
                <ToolkitText key={i}>
                  {toolkitItem.text}
                </ToolkitText>
              )
            }
          </Box>
        )
      }
      
      <Icon as={HiOutlineInformationCircle} fontSize="20px" color="#607B96" cursor="pointer" onClick={()=>setShowToolkit(!showToolkit)}/>

      <Slider orientation='vertical' max={toolkits.length-1} marginTop="13px" value={props.value} onChange={props.onChange}>
        
        {
          [...Array(toolkits.length)].map((toolkit,i)=>
            <SliderMarkText key={i} value={i}/>
          )
        }

        <SliderTrack width="4px" backgroundColor="#171717">
          <SliderFilledTrack backgroundColor="#607B96"/>
        </SliderTrack>

        <SliderThumb backgroundColor="#607B96" width="16px" height="16px" border="1px solid #fff!important" boxShadow="none!important" />

      </Slider>
    </Box>
  )
}

interface ToolkitTextProps {
  children: string
}

const ToolkitText:FC<ToolkitTextProps> = (props) => {
  return (
    <Text whiteSpace="nowrap" fontSize="8px">
      {props.children}
    </Text>
  )
}

interface SliderMarkTextProps {
  value: number
}

const SliderMarkText:FC<SliderMarkTextProps> = (props) => {  
  return (
    <SliderMark value={props.value} left="0" zIndex="1">
      <Box width="16px" height="2px" backgroundColor="#607B96" borderRadius="3px"/>
    </SliderMark>
  )
}

export default InputSliderVertical