import React, { FC, useEffect, useState } from "react"
import axios from "../../../axios"

import { Box } from "@chakra-ui/react"
import { motion } from "framer-motion"

import LevelCard from "../../NotificationCard"

const Notifications:FC = () => {

  const [notifications, setNotifications] = useState([])

  function getActivities(){
    axios.get("/levels/activities?limit=50").then((res)=>{
      setNotifications(res.data)
      console.log(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    getActivities()
  },[])
  
  return (
    <>
      {
        notifications.map((notification,i)=>
          <Box key={i} as={motion.div} 
            initial={{y: 10, opacity: 0}}
            whileInView={{y: 0, opacity: 1}}
            viewport={{once:true}}
            transition={`${i*0.1}s`}
          >
            <LevelCard levelData={notification} showNotificationText showLevel showButtonShow showDescription showMessage width="330px" margin='0 auto 20px auto'/>
          </Box>
        )
      }
    </>
  )
}

export default Notifications