import React, {useState, useEffect} from "react"
import { useSelector, useDispatch } from "react-redux"
import { changeXSize, changeYSize, toggleCellAvailability } from "../../../redux/levelSlice"
import { InterfaceAllSlices } from "../../../typescript/interfaceAllSlices"

import {Box, Icon} from "@chakra-ui/react"
import {ImPlus} from "react-icons/im"

import InputSlider from "../../Inputs/InputSlider"


const GridSizeAndPlayableFields = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()

  function onChangeXSize(val:string){
    const data = {
      xSize: Number(val),
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeXSize(data))
  }

  function onChangeYSize(val:string){
    const data = {
      ySize: Number(val),
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(changeYSize(data))
  }

  function onClickToggleCellAvailability({x,y}:{x:number,y:number}){
    const data = {
      x: Number(x),
      y: Number(y),
      editable: editorStates.purpose === "new" ? true : editorStates.editable
    }
    dispatch(toggleCellAvailability(data))
  }
  
  return (
    <Box pt="100px" mx="auto" width="300px" display="flex" flexDir="column">

      <Box m="0 auto 40px auto" w="95%">
        <InputSlider label="Width" width="100%" margin="0 auto 20px auto" value={levelStates.xSize} min={3} max={8} onChange={(val)=>onChangeXSize(val)}/>
        <InputSlider label="Height" width="100%" margin="0 auto" value={levelStates.ySize} min={3} max={9} onChange={(val)=>onChangeYSize(val)}/>
      </Box>
      
      <Box display="flex" flexDir="column" m="auto" w="fit-content" mx="auto">
        {
          [...Array(levelStates.ySize)].map((row,i)=>
            <Box key={i} display="flex">
              {
                levelStates.tileDataContainers.filter((cell)=>cell.column === i && cell.row < levelStates.xSize).map((cell,k)=>                    
                  <Box key={k} w="32px" h="32px" border="2px solid" borderColor={cell.blockerData[0].type !== "Void" ? "#4BB34B" : "#333"} borderRadius="6px" display="flex" alignItems="center" justifyContent="center" m="2px" cursor="pointer" transition="200ms" onClick={()=>onClickToggleCellAvailability({x:cell.row, y:cell.column})}>
                    <Icon as={ImPlus} fontSize="14px" color={cell.blockerData[0].type !== "Void" ? "#4BB34B" : "#333"} sx={{transform: cell.blockerData[0].type !== "Void" === true ? "rotate(0deg)" : "rotate(-45deg)"}} transition="200ms"/>
                  </Box>
                )
              }
            </Box>
          ).sort((a,b) => a > b ? 1 : -1)
        }
      </Box>
    </Box>
  )
}

export default GridSizeAndPlayableFields