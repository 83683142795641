import React, {useEffect, useState} from "react"
import { useDispatch } from "react-redux"
import { changeClipboard } from "../../../redux/editorSlice"
import { InterfaceCellFeatures } from "../../../typescript/interfaceCellFeatures"

import {Box} from "@chakra-ui/react"
import InputSelectItem from "../../Inputs/InputSelectItem"

import cellFeatures from "../../../assets/fakeData/cellFeatures"

const CellFeatures = () => {

  const dispatch = useDispatch()

  const [teleports, setTeleports] = useState<InterfaceCellFeatures[]>([])
  const [conveyors, setConveyors] = useState<InterfaceCellFeatures[]>([])
  const [walls, setWalls] = useState<InterfaceCellFeatures[]>([])

  function getCellFeatures(){
    const localTeleports:InterfaceCellFeatures[] = []
    const localConveyors:InterfaceCellFeatures[] = []
    const localWalls:InterfaceCellFeatures[] = []
    
    cellFeatures.map((cellFeature,i)=>{

      if(cellFeature.type === "teleport"){
        localTeleports.push(cellFeature)
      } else if(cellFeature.type === "conveyor"){
        localConveyors.push(cellFeature)
      } else if(cellFeature.type === "wall"){
        localWalls.push(cellFeature)
      }
      
    })

    setTeleports(localTeleports)
    setConveyors(localConveyors)
    setWalls(localWalls)
  }

  useEffect(()=>{
    getCellFeatures()
  },[])
  
  return (
    <Box pt="100px" mx="auto" w="fit-content">
      <InputSelectItem placeholder="Teleport" dropdownItems={teleports} dropdownItemsGrid={2} onClick={(e)=>dispatch(changeClipboard({target: 0, gameElementType: e.target.dataset.text, gameElementType2: e.target.dataset.text2}))}/>
      <InputSelectItem placeholder="Conveyor" dropdownItems={conveyors} dropdownItemsGrid={2} onClick={(e)=>dispatch(changeClipboard({target: 0, gameElementType: e.target.dataset.text, gameElementType2: e.target.dataset.text2}))}/>
      <InputSelectItem placeholder="Wall" dropdownItems={walls} dropdownItemsGrid={3} onClick={(e)=>dispatch(changeClipboard({target: 0, gameElementType: e.target.dataset.text, gameElementType2: e.target.dataset.text2}))}/>
    </Box>
  )
}

export default CellFeatures