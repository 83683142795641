import React, { FC, useState, useRef, LegacyRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { addNewMessage } from "../redux/levelSlice"
import { InterfaceAllSlices } from "../typescript/interfaceAllSlices"

import { Box, Heading, Text } from "@chakra-ui/react"

import InputTextarea from "./Inputs/InputTextarea"
import InputButtonText from "./Inputs/InputButtonText"

import { motion } from "framer-motion"

interface Props {
  width?: string
}

const Chat:FC<Props> = (props) => {

  const userStates = useSelector((state:InterfaceAllSlices) => state.user)
  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const dispatch = useDispatch()

  const messageBoxRef:LegacyRef<any> = useRef()

  const [textarea, setTextarea] = useState("")

  useEffect(()=>{
    if(messageBoxRef.current){
      const lastMessageIndex = messageBoxRef.current.children.length - 1
      const lastMessage = messageBoxRef.current.children[lastMessageIndex]
      lastMessage?.scrollIntoView()
    }
  },[levelStates.chat])

  function sendMessage(){

    if(textarea.trim().length){

      const newMessage = {
        name: userStates.name,
        message: textarea
      }

      dispatch(addNewMessage(newMessage))
      
      setTextarea("")
    }
    
  }
  
  return (
    <Box border="1px solid #fff" borderRadius="8px" w={props.width ? props.width : ""} minH="490px" p="12px" display="flex" flexDir="column">
      <Box h="100%" maxH="345px" mb="auto" overflowY="auto" pr="10px" ref={messageBoxRef} sx={{"&::-webkit-scrollbar": {width: "10px", border: "1px solid #fff", borderRadius: "8px"}, "&::-webkit-scrollbar-thumb": {bg: "#fff", borderRadius: "8px"}}}>
        {
          levelStates.chat?.map((message,i)=>
            <Box key={i} as={motion.div} initial={{scale: 0.9, opacity: 0, translateX: -50}} animate={{scale: 1, opacity: 1, translateX: 0}} display="flex" flexDir="column" w="fit-content" maxW="60%" p="8px" border="1px solid #fff" borderRadius="8px" mb="8px" ml={message.name === userStates.name ? "auto" : ""} bg={message.name === "Me" ? "#1E1E1E" : ""} sx={{"&:last-child":{mb:0}}}>
              <Heading as="h5" fontSize="12px" fontWeight="700">{message.name === userStates.name ? "Me" : message.name}</Heading>
              <Text fontSize="10px" fontWeight="500">{message.message}</Text>
            </Box>
          )
        }
      </Box>
      <Box pos="relative" marginTop="15px">
        <InputTextarea height="105px" minHeight="105px"  margin="0" placeholder="Type something..." value={textarea} onChange={(e)=>setTextarea(e.target.value)}/>
        <Box pos="absolute" right="15px" bottom="10px" zIndex="2">
          <InputButtonText label='Send' icon='send' onClick={()=>sendMessage()}/>
        </Box>
      </Box>
    </Box>
  )
}

export default Chat