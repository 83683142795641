import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  fonts: {
    heading: "'Poppins' sans-serif",
    body: "'Poppins' sans-serif"
  },
  styles: {
    global: {
      body: {
        bg: "#171717",
        color: "#fff"
      },
      "*": {
        WebkitTapHighlightColor: "transparent"
      }
    }
  }
})

export default theme