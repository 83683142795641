import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { InterfaceLevelSlice, InterfaceTileDataContainers } from "../typescript/interfaceLevelSlice"

const tileDataContainers:InterfaceTileDataContainers[] = []

for (let i = 8; i >= 0; i--) {
  for (let k = 0; k <= 7; k++) {
    tileDataContainers.push({
      isBottomEndTile: i === 0 ? true : false,
      row: k,
      column: i,
      squareData: {
        gameElementType: "None",
        gameElementType2: "none",
        spawnerType: "Spawner",
        blueStoneRatio: 40,
        yellowStoneRatio: 40,
        pinkStoneRatio: 20,
        purpleStoneRatio: 0,
        greenStoneRatio: 0,
        animalChance: 40,
        animal1Type: "Bear",
        animal2Type: "None",
        animal3Type: "None",
        animal1Count: 3,
        animal2Count: 0,
        animal3Count: 0,
      },
      itemData: {
        type: "None",
        type2: "none",
        yielderType: "None",
        assetName: "None"
      },
      blockerData: [{
        type: "None",
        type2: "none",
        biomeType: "Plain",
        voidBlockerType: "Default",
        humanoidCoverBlockerType: "AnimalNet",
        humanoidBreakableBlockerType: "HumanoidRock",
        assetName: "None",
        blockerState: 0
      }]
    })
  }
}

const initialState:InterfaceLevelSlice = {
  xSize: 8,
  ySize: 9,
  levelNumber: 1,
  biomeType: "Plain",
  eventCollectionType: "Biom Essence",
  yielderType: "None",
  objectiveDataContainer: {
    objective01: {
      type: "None",
      type2: "none",
      yielderType: "None",
      biome: "Plain",
      count: 0,
      humanoidBreakableBlockerType: "HumanoidRock",
      humanoidCoverBlockerType: "AnimalNet"
    },
    objective02: {
      type: "None",
      type2: "none",
      yielderType: "None",
      biome: "Plain",
      count: 0,
      humanoidBreakableBlockerType: "HumanoidRock",
      humanoidCoverBlockerType: "AnimalNet"
    },
    objective03: {
      type: "None",
      type2: "none",
      yielderType: "None",
      biome: "Plain",
      count: 0,
      humanoidBreakableBlockerType: "HumanoidRock",
      humanoidCoverBlockerType: "AnimalNet"
    },
    timeObjective: 0,
    moveObjective: 0,
    gameMode: false
  },
  tileDataContainers: tileDataContainers,
  randomStoneList: {
    hasBlueRatio: true,
    hasYellowRatio: true,
    hasPinkRatio: true,
    hasPurpleRatio: true,
    hasGreenRatio: true,
  },
  humanoidObstacleDataContainer: {
    moveFrequency: 0,
    humanoidObstacleTypes: "None",
    createBlockerOnStart: false
  },
  maxAnimals: 5,
  hintType: "BestMove",
  tutorialAssetName: "None",
  experience: 0,
  gold: 0,
  description: `This level created on ${new Date().toDateString()} at ${new Date().toLocaleTimeString()}`,
  createdBy: "",
  almight: {
    almightCharge: "None",
    almight: "None"
  },
  chat: [],
  savedSpawners: []
}

const levelSlice = createSlice({
  name: "level",
  initialState,
  reducers: {
    changeBiome: (state, action) => {
      state.biomeType = action.payload
    },
    changeGameMode: (state, action) => {
      state.objectiveDataContainer.gameMode = action.payload
    },
    changeLevelNumber: (state, action) => {
      
      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change level number.")
      } else {
        state.levelNumber = action.payload.levelNumber
      }
      
    },
    changeDescription: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change description.")
      } else {
        state.description = action.payload.description
      }
      
    },
    addNewMessage: (state, action) => {
      state.chat.push(action.payload)
    },
    changeXSize: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change width.")
      } else {
        state.xSize = action.payload.xSize
      }
      
    },
    changeYSize: (state, action) => {
      
      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change height.")
      } else {
        state.ySize = action.payload.ySize
      }
      
    },
    toggleCellAvailability: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change cell availablity.")
      } else {
        
        state.tileDataContainers.map((cell)=>{
          if(cell.row === action.payload.x && cell.column === action.payload.y){
  
            if(cell.blockerData[0].type === "Void" && cell.blockerData[0].type2 === "void"){
              cell.blockerData[0].type = "None"
              cell.blockerData[0].type2 = "none"
            } else {
              cell.blockerData[0].type = "Void"
              cell.blockerData[0].type2 = "void"
              cell.itemData.type = "None"
              cell.itemData.type2 = "none"
              cell.squareData.gameElementType = "None"
              cell.squareData.gameElementType2 = "none"
            }
  
          }
        })
        
      }
      
    },
    changeObjective01: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change objective 1.")
      } else {
        state.objectiveDataContainer.objective01.type = action.payload.type
        state.objectiveDataContainer.objective01.type2 = action.payload.type2
        state.objectiveDataContainer.objective01.yielderType = action.payload.yielderType
        state.objectiveDataContainer.objective01.count = action.payload.count
      }
      
    },
    changeObjective02: (state, action) => {
      
      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change objective 2.")
      } else {
        state.objectiveDataContainer.objective02.type = action.payload.type
        state.objectiveDataContainer.objective02.type2 = action.payload.type2
        state.objectiveDataContainer.objective02.yielderType = action.payload.yielderType
        state.objectiveDataContainer.objective02.count = action.payload.count
      }
      
    },
    changeObjective03: (state, action) => {
     
      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change objective 3.")
      } else {
        state.objectiveDataContainer.objective03.type = action.payload.type
        state.objectiveDataContainer.objective03.type2 = action.payload.type2
        state.objectiveDataContainer.objective03.yielderType = action.payload.yielderType
        state.objectiveDataContainer.objective03.count = action.payload.count
      }
      
    },
    changeMove: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change move.")
      } else {
        state.objectiveDataContainer.moveObjective = action.payload.moveObjective
      }

    },
    changeTime: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change time.")
      } else {
        state.objectiveDataContainer.timeObjective = action.payload.timeObjective
      }

    },
    changeRandomStoneList: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change random stone list.")
      } else {
        const {editable, ...otherData} = action.payload
        state.randomStoneList = otherData
      }
      
    },
    changeRewards: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change rewards.")
      } else {
        state.gold = action.payload.gold
        state.experience = action.payload.experience
      }
      
    },
    changeCellData: (state, action) => {
      
      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change cells.")
      } else {
        const localTiles = [...state.tileDataContainers]
        
        localTiles.map((cell)=>{
          if(cell.row === action.payload.x && cell.column === action.payload.y){
  
            if(action.payload.newData.target === 0){
  
              if(cell.squareData.gameElementType === "None"){
                cell.squareData.gameElementType = action.payload.newData.gameElementType
                cell.squareData.gameElementType2 = action.payload.newData.gameElementType2
              } else if(cell.squareData.gameElementType !== "None"){
                cell.squareData.gameElementType = "None"
                cell.squareData.gameElementType2 = "none"
              }
              
            }
  
            if(action.payload.newData.target === 1){
  
              if(cell.itemData.type === "None"){
                cell.itemData.type = action.payload.newData.type
                cell.itemData.type2 = action.payload.newData.type2
                cell.itemData.yielderType = action.payload.newData.yielderType
              } else if(cell.itemData.type !== "None"){
                cell.itemData.type = "None"
                cell.itemData.type2 = "none"
                cell.itemData.yielderType = "None"
              }
              
            }
  
            if(action.payload.newData.target === 2){
  
              if(cell.blockerData[0].type === "None"){
                cell.blockerData[0].type = action.payload.newData.type
                cell.blockerData[0].type2 = action.payload.newData.type2
                cell.blockerData[0].blockerState = action.payload.newData.blockerState
              } else if(cell.blockerData[0].type !== "None"){
                cell.blockerData[0].type = "None"
                cell.blockerData[0].type2 = "none"
                cell.blockerData[0].blockerState = 0
              }
              
            }
          }
        })
  
        state.tileDataContainers = localTiles
      }

    },
    addNewSpawner: (state, action) => {
      const newSpawner = {
        blueStoneRatio: action.payload.blueStoneRatio,
        yellowStoneRatio: action.payload.yellowStoneRatio,
        pinkStoneRatio: action.payload.pinkStoneRatio,
        purpleStoneRatio: action.payload.purpleStoneRatio,
        greenStoneRatio: action.payload.greenStoneRatio,
        animalChance: action.payload.animalChance,
        animal1Type: action.payload.animal1Type,
        animal1Type2: action.payload.animal1Type2,
        animal2Type: action.payload.animal2Type,
        animal2Type2: action.payload.animal2Type2,
        animal3Type: action.payload.animal3Type,
        animal3Type2: action.payload.animal3Type2,
        animal1Count: action.payload.animal1Count,
        animal2Count: action.payload.animal2Count,
        animal3Count: action.payload.animal3Count,
      }

      state.savedSpawners.push(newSpawner)
    },
    removeSavedSpawner: (state, action) => {

      const localSavedSpawners = [...state.savedSpawners]

      localSavedSpawners.slice(action.payload, 1)

      state.savedSpawners = localSavedSpawners
      
    },
    changeMaxAnimals: (state, action) => {
      state.maxAnimals = action.payload
    },
    changeAlmight: (state, action) => {

      if(!action.payload.editable){
        toast.info("You need to turn edit mode on to change rewards.")
      } else {
        state.almight.almightCharge = action.payload.almightCharge
        state.almight.almight = action.payload.almight
      }
      
    },
    importLevel: (state, action) => {      
      state.xSize = action.payload.xSize,
      state.ySize = action.payload.ySize,
      state.levelNumber = action.payload.number,
      state.biomeType = action.payload.biomeType,
      state.eventCollectionType = action.payload.eventCollectionType,
      state.yielderType = action.payload.yielderType,
      state.objectiveDataContainer = {
        objective01: {
          type: action.payload.objectiveDataContainer.objective01.type,
          type2: action.payload.objectiveDataContainer.objective01.type2,
          yielderType: action.payload.objectiveDataContainer.objective01.yielderType,
          biome: action.payload.objectiveDataContainer.objective01.biome,
          count: action.payload.objectiveDataContainer.objective01.count,
          humanoidBreakableBlockerType: action.payload.objectiveDataContainer.objective01.humanoidBreakableBlockerType,
          humanoidCoverBlockerType: action.payload.objectiveDataContainer.objective01.humanoidCoverBlockerType
        },
        objective02: {
          type: action.payload.objectiveDataContainer.objective02.type,
          type2: action.payload.objectiveDataContainer.objective02.type2,
          yielderType: action.payload.objectiveDataContainer.objective02.yielderType,
          biome: action.payload.objectiveDataContainer.objective02.biome,
          count: action.payload.objectiveDataContainer.objective02.count,
          humanoidBreakableBlockerType: action.payload.objectiveDataContainer.objective02.humanoidBreakableBlockerType,
          humanoidCoverBlockerType: action.payload.objectiveDataContainer.objective02.humanoidCoverBlockerType
        },
        objective03: {
          type: action.payload.objectiveDataContainer.objective03.type,
          type2: action.payload.objectiveDataContainer.objective03.type2,
          yielderType: action.payload.objectiveDataContainer.objective03.yielderType,
          biome: action.payload.objectiveDataContainer.objective03.biome,
          count: action.payload.objectiveDataContainer.objective03.count,
          humanoidBreakableBlockerType: action.payload.objectiveDataContainer.objective03.humanoidBreakableBlockerType,
          humanoidCoverBlockerType: action.payload.objectiveDataContainer.objective03.humanoidCoverBlockerType
        },
        timeObjective: action.payload.objectiveDataContainer.timeObjective,
        moveObjective: action.payload.objectiveDataContainer.moveObjective,
        gameMode: action.payload.objectiveDataContainer.gameMode
      },
      state.tileDataContainers = [
        ...action.payload.tileDataContainers.map((cell:any)=>({
          isBottomEndTile: cell.isBottomEndTile,
          row: cell.row,
          column: cell.column,
          squareData: {
            gameElementType: cell.squareData.gameElementType,
            gameElementType2: cell.squareData.gameElementType2,
            spawnerType: cell.squareData.spawnerType,
            blueStoneRatio: cell.squareData.blueStoneRatio,
            yellowStoneRatio: cell.squareData.yellowStoneRatio,
            pinkStoneRatio: cell.squareData.pinkStoneRatio,
            purpleStoneRatio: cell.squareData.purpleStoneRatio,
            greenStoneRatio: cell.squareData.greenStoneRatio,
            animalChance: cell.squareData.animalChance,
            animal1Type: cell.squareData.animal1Type,
            animal2Type: cell.squareData.animal2Type,
            animal3Type: cell.squareData.animal3Type,
            animal1Count: cell.squareData.animal1Count,
            animal2Count: cell.squareData.animal2Count,
            animal3Count: cell.squareData.animal3Count,
          },
          itemData: {
            type: cell.itemData.type,
            type2: cell.itemData.type2,
            yielderType: cell.itemData.yielderType,
            assetName: cell.itemData.assetName
          },
          blockerData: [{
            type: cell.blockerData[0].type,
            type2: cell.blockerData[0].type2,
            biomeType: cell.blockerData[0].biomeType,
            voidBlockerType: cell.blockerData[0].voidBlockerType,
            humanoidCoverBlockerType: cell.blockerData[0].humanoidCoverBlockerType,
            humanoidBreakableBlockerType: cell.blockerData[0].humanoidBreakableBlockerType,
            assetName: cell.blockerData[0].assetName,
            blockerState: cell.blockerData[0].blockerState,
          }]
        }))
      ],
      state.randomStoneList = {
        hasBlueRatio: action.payload.randomStoneList.hasBlueRatio,
        hasYellowRatio: action.payload.randomStoneList.hasYellowRatio,
        hasPinkRatio: action.payload.randomStoneList.hasPinkRatio,
        hasPurpleRatio: action.payload.randomStoneList.hasPurpleRatio,
        hasGreenRatio: action.payload.randomStoneList.hasGreenRatio
      },
      state.humanoidObstacleDataContainer = {
        moveFrequency: action.payload.humanoidObstacleDataContainer.moveFrequency, //new ui needed to add this info
        humanoidObstacleTypes: action.payload.humanoidObstacleDataContainer.humanoidObstacleTypes, //new ui needed to add this info or None
        createBlockerOnStart: action.payload.humanoidObstacleDataContainer.createBlockerOnStart //new ui needed to add this info or False
      },
      state.maxAnimals = action.payload.maxAnimals,
      state.hintType = action.payload.hintType,
      state.tutorialAssetName = action.payload.tutorialAssetName, //info needed
      state.experience = action.payload.experience,
      state.gold = action.payload.gold,
      state.description = action.payload.description,
      state.createdBy = action.payload.createdBy,
      state.almight = {
        almightCharge: action.payload.almight.almightCharge,
        almight: action.payload.almight.almight
      },
      state.chat = action.payload.chat.map((message:any)=>({
        name: message.name,
        message: message.message
      })),
      state.savedSpawners = action.payload.savedSpawners.map((savedSpawner:any)=>({
        blueStoneRatio: savedSpawner.blueStoneRatio,
        yellowStoneRatio: savedSpawner.yellowStoneRatio,
        pinkStoneRatio: savedSpawner.pinkStoneRatio,
        purpleStoneRatio: savedSpawner.purpleStoneRatio,
        greenStoneRatio: savedSpawner.greenStoneRatio,
        animalChance: savedSpawner.animalChance,
        animal1Type: savedSpawner.animal1Type,
        animal1Type2: savedSpawner.animal1Type2,
        animal2Type: savedSpawner.animal2Type,
        animal2Type2: savedSpawner.animal2Type2,
        animal3Type: savedSpawner.animal3Type,
        animal3Type2: savedSpawner.animal3Type2,
        animal1Count: savedSpawner.animal1Count,
        animal2Count: savedSpawner.animal2Count,
        animal3Count: savedSpawner.animal3Count
      }))
    },
    resetLevel: () => initialState
  }
})

export const {changeBiome, changeGameMode, changeLevelNumber, changeDescription, addNewMessage, changeXSize, changeYSize, toggleCellAvailability, 
  changeObjective01, changeObjective02, changeObjective03, changeMove, changeTime, changeRandomStoneList, changeRewards, 
  changeCellData, addNewSpawner, removeSavedSpawner, changeMaxAnimals, changeAlmight, importLevel, resetLevel} = levelSlice.actions
export default levelSlice.reducer