import React, {useEffect, useState} from "react"
import { useNavigate } from "react-router-dom"
import Cookies from "universal-cookie"
import axios from "axios"
import {toast} from "react-toastify"

import {useAppDispatch, useAppSelector} from "../hooks/useTypedSelectors"
import { InterfaceAllSlices } from "../typescript/interfaceAllSlices"

import {Box, Image, Text} from "@chakra-ui/react"

import InputText from "../Components/Inputs/InputText"
import InputButton from "../Components/Inputs/InputButton"

import Logo from "../assets/images/logo/logo-leveleditor.png"
import { loginAuth } from "../redux/userSlice"

const Login = () => {

  const navigate = useNavigate()
  const userStates = useAppSelector((state:InterfaceAllSlices) => state.user)
  const dispatch = useAppDispatch()

  const [inputs, setInputs] = useState({
    email: "",
    password: ""
  })
  const [buttonLoading, setButtonLoading] = useState(false)

  function setInputsFunc(e:any){
    setInputs(prev=>({...prev, [e.target.name]: e.target.value}))
  }

  useEffect(()=>{

    setButtonLoading(userStates.requestStatus.loading)

    if(userStates.requestStatus.error){
      toast.error(userStates.requestStatus.message)
    }

    if(userStates.token){
      navigate("/")
    }
    
  },[userStates])
  
  function loginRequest(){

    const userData = {
      email: inputs.email, 
      password: inputs.password
    }

    dispatch(loginAuth(userData))
  
  }
  
  return (
    <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
      <Box>
        <Image src={Logo} marginBottom="35px" width="451px"/>
        <Box pos="relative" w="fit-content" m="auto" display="flex" flexDir="column" alignItems="center">
          <InputText label='Email' type="email" name="email" value={inputs.email} sx={{width: "350px" }} onKeyDown={(e)=>e.key === "Enter" && loginRequest()} onChange={setInputsFunc}/>
          <InputText label='Password' type="password" name="password" value={inputs.password} sx={{width: "350px" }} onKeyDown={(e)=>e.key === "Enter" && loginRequest()} onChange={setInputsFunc}/>
          <InputButton label='Login' loading={buttonLoading} margin='0 0 10px 0' onClick={()=>loginRequest()}/>
          <Text>&copy; LostCtrl 2022</Text>
        </Box>
      </Box>
    </Box>
  )
}

export default Login