import { createSlice } from "@reduxjs/toolkit"

const popupManagerSlice = createSlice({
  name: "popupManager",
  initialState: {
    popupNewLevel: {
      status: false
    },
    popupError: {
      status: false,
      title: "Error",
      description: "An unexpected error has occurred."
    },
    popupDelete: {
      status: false,
      title: "ARE YOU SURE THAT YOU WANT TO DELETE IT?",
      description: "You won't be able to revert."
    }
  },
  reducers: {
    collapsePopupNewLevel: (state, action) => {
      state.popupNewLevel.status = action.payload
    },
    collapsePopupError: (state, action) => {
      state.popupError.status = action.payload
    },
    collapsePopupDelete: (state, action) => {
      state.popupDelete.status = action.payload
    }
  }
})

export const {collapsePopupNewLevel, collapsePopupError, collapsePopupDelete} = popupManagerSlice.actions
export default popupManagerSlice.reducer