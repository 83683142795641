import React, {useEffect, useState} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "../axios"
import { toast } from "react-toastify"

import { InterfaceSpawner } from "../typescript/interfaceSpawner"
import { InterfaceBlocker } from "../typescript/interfaceBlocker"

import { Box, Heading, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, Icon, Image } from "@chakra-ui/react"
import {BsChevronRight as RightIcon} from "react-icons/bs"

import InputButtonIcon from "../Components/Inputs/InputButtonIcon"

const biomes = [
  {
    name: "All (Mutuals)",
    name2: "all"
  },
  {
    name: "Plain",
    name2: "plain"
  },
  {
    name: "Forest",
    name2: "forest"
  },
  {
    name: "Mountain",
    name2: "mountain"
  },
  {
    name: "Aquatic",
    name2: "aquatic"
  },
  {
    name: "Arctic",
    name2: "arctic"
  },
  {
    name: "Desert",
    name2: "desert"
  }
]

const blockerTypes = [
  {
    name: "Breakable Blockers",
    name2: 0
  },
  {
    name: "Collectible Blockers",
    name2: 1
  },
  {
    name: "Cover Blockers",
    name2: 2
  },
  {
    name: "Humanoid Blockers",
    name2: 3
  },
  {
    name: "Random Humanoid Blockers",
    name2: 4
  }
]

const spawnerTypes = [
  {
    name: "Color Stones",
    name2: 0
  },
  {
    name: "Power Stones",
    name2: 1
  },
  {
    name: "Droppables",
    name2: 2
  },
]

const Assets = () => {

  const location = useLocation()
  const navigate = useNavigate()

  const page = location.pathname.split("/")[1] //blockers - spawners
  const assetTypes = page === "blockers" ? blockerTypes : spawnerTypes
  
  const [assets, setAssets] = useState<InterfaceSpawner[]>([])

  function getAssets(){
    axios.get(page).then((res)=>{
      setAssets(res.data)
    }).catch((err)=>{
      toast.error(err.response.data.message)
    })
  }

  useEffect(()=>{
    getAssets()
  },[location.pathname])
  
  return (
    <Box mt="90px" mx="auto" w="1000px">

      {
        biomes.map((biome, i)=>
          <Box key={i} mb="20px">

            <Box display="flex" alignItems="center">
              <Heading fontWeight="600" fontSize="16px">{biome.name}</Heading>
              <InputButtonIcon icon="add" size="md" margin="0 0 0 auto" onClick={()=>navigate(page === "blockers" ? "/blockers/new-blocker" : "/spawners/new-spawner", {state: {selectedBiome: biome.name2}})}/>
            </Box>

            <Accordion allowMultiple defaultIndex={[]} w="100%" mb="40px">
              { 
                assetTypes.map((assetType, j)=>
                  assets?.filter(asset => asset.type === assetType.name2 && asset.biome === biome.name2).length > 0 &&
                    <AccordionItem key={j} border="0" mt="18px">
                      {
                        ({isExpanded})=>
                          <>
                            <AccordionButton border="1px solid #fff" borderRadius="8px">
                              <Text>{assetType.name}</Text>
                              <Icon as={RightIcon} ml="auto" sx={{transform: isExpanded ? "rotate(90deg)" : ""}} transition="0.2s"/>
                            </AccordionButton>
                            
                            <AccordionPanel mt="8px" p="0" border="1px solid #fff" borderRadius="8px">
                              <Box display="flex" flexWrap="wrap" p="12px">
                                {
                                  assets?.filter(asset => asset.type === assetType.name2 && asset.biome === biome.name2).map((asset, k)=>
                                    <Box key={k} w="33.33%" h="360px" padding="12px">
                                      <Box border="1px solid #fff" w="100%" h="100%" borderRadius="15px" pos="relative">
                                        <Image src={`https://nora-level-editor-assets.s3.us-east-2.amazonaws.com/${asset.image}`} w="auto" h="216px" sx={{pos: "absolute", left: "50%", top: "30%", transform: "translate(-50%,-30%)"}}/>
                                        <Text fontSize="24px" fontWeight="400" pos="absolute" left="20px" bottom="10px" maxW="200px">{asset.name}</Text>
                                        <InputButtonIcon icon="edit" size="md" sx={{pos: "absolute", right: "20px", bottom: "15px"}} onClick={()=>navigate(`/${page}/edit-${page === "blockers" ? "blocker" : "spawner"}?id=${asset.id}&type=${asset.type}`)}/>
                                      </Box>
                                    </Box>
                                  )
                                }
                              </Box>
                            </AccordionPanel>
                          </>
                      }
                    </AccordionItem>
                )
              }
            </Accordion>
          </Box>
        )
      }

    </Box>
  )
}

export default Assets