import { createSlice } from "@reduxjs/toolkit"
import { InterfaceEditorSlice } from "../typescript/interfaceEditorSlice"

const initialState:InterfaceEditorSlice = {
  sidebar: "General Level Settings",
  clipboard:{
    target: 0,
    type: "",
    type2: "",
    gameElementType: "",
    gameElementType2: "",
    yielderType: "",
    blueStoneRatio: 0,
    yellowStoneRatio: 0,
    pinkStoneRatio: 0,
    purpleStoneRatio: 0,
    greenStoneRatio: 0,
    animalChance: 0,
    animal1Type: "",
    animal2Type: "",
    animal3Type: "",
    animal1Count: 0,
    animal2Count: 0,
    animal3Count: 0,
    blockerState: 0,
  },
  cellsLevel: 0,
  editable: false,
  purpose: "new"
}

const editorSlice = createSlice({
  name: "editor",
  initialState: initialState,
  reducers: {
    changeSidebar: (state, action) => {
      state.sidebar = action.payload
    },
    changeClipboard: (state, action) => {
      state.clipboard.target = action.payload.target
      state.clipboard.type = action.payload.type
      state.clipboard.type2 = action.payload.type2
      state.clipboard.gameElementType = action.payload.gameElementType
      state.clipboard.gameElementType2 = action.payload.gameElementType2
      state.clipboard.yielderType = action.payload.yielderType
      state.clipboard.blueStoneRatio = action.payload.blueStoneRatio
      state.clipboard.yellowStoneRatio = action.payload.yellowStoneRatio
      state.clipboard.pinkStoneRatio = action.payload.pinkStoneRatio
      state.clipboard.purpleStoneRatio = action.payload.purpleStoneRatio
      state.clipboard.greenStoneRatio = action.payload.greenStoneRatio
      state.clipboard.animalChance = action.payload.animalChance
      state.clipboard.animal1Type = action.payload.animal1Type
      state.clipboard.animal2Type = action.payload.animal2Type
      state.clipboard.animal3Type = action.payload.animal3Type
      state.clipboard.animal1Count = action.payload.animal1Count
      state.clipboard.animal2Count = action.payload.animal2Count
      state.clipboard.animal3Count = action.payload.animal3Count
      state.clipboard.blockerState = action.payload.blockerState
    },
    changeCellsLevel: (state, action) => {
      state.cellsLevel = action.payload
    },
    changeEditable: (state, action) => {
      state.editable = action.payload
    },
    changePurpose: (state, action) => {
      state.editable = action.payload
    }
  }
})

export const {changeSidebar, changeClipboard, changeCellsLevel, changeEditable, changePurpose} = editorSlice.actions
export default editorSlice.reducer