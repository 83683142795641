import React, { FC } from "react"
import { useSelector, useDispatch } from "react-redux"

import { changeSidebar } from "../../redux/editorSlice"
import { InterfaceAllSlices } from "../../typescript/interfaceAllSlices"

import { Box, Heading, UnorderedList, Text, ListItem, Icon, Accordion, AccordionItem, AccordionButton, AccordionPanel } from "@chakra-ui/react"
import {BsChevronRight as RightIcon} from "react-icons/bs"
import {motion} from "framer-motion"

import sidebar from "../../assets/fakeData/sidebar"

const LeftPart:FC = () => {

  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()
  
  return (
    <Box height="calc(100vh - 60px)" w="390px" borderLeft="1px solid #1E2D3D" borderRight="1px solid #1E2D3D">
      <Accordion allowMultiple defaultIndex={[0,1]}>
        {
          sidebar.map((menuPart,i)=>
            <AccordionItem key={i} border="0">
              {({isExpanded}) => 
                <>
                  <AccordionButton height="50px" display="flex" justifyContent="flex-start" alignItems="center" borderBottom="1px solid #1E2D3D" p="0 0 0 17.5px" cursor="pointer" transition="0.2s" _hover={{bg: "rgba(0,0,0,0.1)"}} _active={{"& h2": {pl: "10px"}}}>
                    <Icon as={RightIcon} mr="10px" sx={{transform: isExpanded ? "rotate(90deg)" : ""}} transition="0.2s"/>
                    <Heading as="h2" fontSize="18px" fontWeight="400" userSelect="none" transition="0.2s">{menuPart.menuTitle}</Heading>
                  </AccordionButton>
                  
                  <AccordionPanel borderBottom="1px solid #1E2D3D" p="0">
                    <UnorderedList listStyleType="none" m="0">
                      {
                        menuPart.menuItems.map((menuItem,k)=>
                          <ListItem key={k} fontSize="16px" fontWeight="400" userSelect="none" p="8px 0 8px 45px" cursor="pointer" display="flex" alignItems="baseline" transition="0.2s" sx={{"&:first-of-type": {pt: "16px"}, "&:last-child": {pb: "16px"}}} _hover={{bg: "rgba(0,0,0,0.075)"}} onClick={()=>dispatch(changeSidebar(menuItem))}>
                            <Text mr="10px">{menuItem}</Text>
                            {editorStates.sidebar === menuItem && <Box as={motion.div} initial={{opacity: 0, translateX: -10}} animate={{opacity: 1, translateX: 0}}><Icon as={RightIcon} fontSize="10px"/></Box>}
                          </ListItem>
                        )
                      }
                    </UnorderedList>
                  </AccordionPanel>
                </>
              }
            </AccordionItem>
          )
        }
      </Accordion>
    </Box>
  )
}

export default LeftPart