import React, { useEffect, useState } from "react"
import {useLocation, useNavigate, useParams} from "react-router-dom"
import axios from "../axios"
import { toast } from "react-toastify"

import { useSelector, useDispatch } from "react-redux"
import { changeObjective01, changeObjective02, changeObjective03, changeCellData, changeMove, changeTime, changeRewards, importLevel, resetLevel } from "../redux/levelSlice"
import { changeCellsLevel, changeEditable } from "../redux/editorSlice"
import { InterfaceAllSlices } from "../typescript/interfaceAllSlices"

import {Box, Button, Icon, Text, Image} from "@chakra-ui/react"
import {IoCloseSharp as CloseIcon} from "react-icons/io5"

import InputButtonText from "../Components/Inputs/InputButtonText"
import InputSwitch from "../Components/Inputs/InputSwitch"
import InputSliderVertical from "../Components/Inputs/InputSliderVertical"
import ImageDynamic from "../Components/ImageDynamic"

const Level = () => {

  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [purpose, setPurpose] = useState(location.pathname.split("/")[2].split("-")[0]) //ner - edit
  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const editorStates = useSelector((state:InterfaceAllSlices) => state.editor)
  const dispatch = useDispatch()

  function removeObjectives(){
    dispatch(changeObjective01({type: "None", type2: "none"}))
    dispatch(changeObjective02({type: "None", type2: "none"}))
    dispatch(changeObjective03({type: "None", type2: "none"}))
  }

  function removeMoveAndTime(){
    dispatch(changeMove(0))
    dispatch(changeTime(0))
  }

  function removeMateriaAndExperience(){
    dispatch(changeRewards({gold:0, experience:0}))
  }

  function changeCellDataFunc(x:number, y:number){

    if(editorStates.clipboard.target === 0 && editorStates.cellsLevel !== 0){
      dispatch(changeCellsLevel(0))
    } else if(editorStates.clipboard.target === 1 && editorStates.cellsLevel !== 1){
      dispatch(changeCellsLevel(1))
    } else if(editorStates.clipboard.target === 2 && editorStates.cellsLevel !== 2){
      dispatch(changeCellsLevel(2))
    }

    levelStates.tileDataContainers.map((cell,i)=>{

      if(cell.row === x && cell.column === y){    

        if(cell.blockerData[0].type !== "Void"){ 

          const data = {
            x: x, 
            y: y, 
            newData: editorStates.clipboard, 
            cellsLevel: editorStates.cellsLevel,
            editable: editorStates.purpose === "new" ? true : editorStates.editable
          }
          
          dispatch(changeCellData(data))
        }
      }
      
    })
    
  }

  function getLevel(){    
    axios.get(`/levels?id=${id}`).then((res)=>{      
      dispatch(importLevel(res.data))
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    if(id){
      getLevel()
    }
  },[id])

  function newLevel(){

    axios.post("/levels", {...levelStates, number: levelStates.levelNumber}).then((res)=>{
      toast.success(res.statusText)
      navigate("/levels")
    }).catch((err)=>{
      toast.error(err.response.data.message)
    })

  }

  function updateLevel(){
    
    axios.put("/levels", {...levelStates, number: levelStates.levelNumber, id: id}).then((res)=>{
      toast.success(res.data)
      navigate("/levels")
    }).catch((err)=>{
      toast.error(err.response.data.message)
    })
  }
  
  return (
    <Box pos="relative" h="100%">
      <Box pos="absolute" left="0" top="0" p="16px">
        <InputButtonText label="Save Level" icon="save" onClick={() => purpose === "new" ? newLevel() : purpose === "edit" ? updateLevel() : null}/>
        <InputButtonText label="Refresh" icon="refresh" onClick={() => console.log("refresh level")}/>
      </Box>

      {
        purpose === "edit" &&
        <Box pos="absolute" right="0" top="0" p="16px">
          <InputSwitch activeLabel="Edit" deactiveLabel="View" value={editorStates.editable} onChange={() => dispatch(changeEditable(!editorStates.editable))}/>
        </Box>
      }

      <Box pos="absolute" left="0" bottom="0">
        <InputSliderVertical borderLeft={false} value={editorStates.cellsLevel} onChange={(val) => dispatch(changeCellsLevel(val))}/>
      </Box>

      <Box pos="absolute" left="50%" top="52px" w="400px" display="flex" flexDir="column" sx={{transform: "translateX(-50%)"}}>
        
        <Box w="100%" h="150px" display="flex" justifyContent="space-between">
          <Box w="49%" h="100%" border="2px solid #fff" borderRadius="8px" pos="relative" px="24px" display="flex" alignItems="center">

            <Button position="absolute" top="0" right="0" p="0" w="15px" h="15px" minW="initial" bg="transparent" _hover={{bg: "transparent"}} _active={{bg: "transparent", transform: "scale(.9)"}} onClick={()=> removeObjectives()}>
              <Icon as={CloseIcon} fontSize="10px"/>
            </Button>

            <Box display="flex" flexDir="column" w="100%">

              <Text fontSize="10px" fontWeight="700" mb="2.5px">Objectives</Text>

              <Box display="flex" flexDir="row" alignItems="center" w="100%">
                <Box w="46px" h="46px" border="1px solid #fff" mx="2.5px" borderRadius="8px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none" sx={{"&:first-of-type": {ml: 0}}}>
                  {
                    levelStates.objectiveDataContainer.objective01.type !== "None" &&
                    <>
                      <ImageDynamic name={levelStates.objectiveDataContainer.objective01.type2} motion="scale" w="26px" h="26px"/>
                      <Text fontSize="10px" fontWeight="700" pos="absolute" right="5px" bottom="5px" lineHeight="1">{levelStates.objectiveDataContainer.objective01.count}</Text>
                    </>
                  }
                </Box>

                <Box w="46px" h="46px" border="1px solid #fff" mx="2.5px" borderRadius="8px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none" sx={{"&:first-of-type": {ml: 0}}}>
                  {
                    levelStates.objectiveDataContainer.objective02.type !== "None" &&
                    <>
                      <ImageDynamic name={levelStates.objectiveDataContainer.objective02.type2} motion="scale" w="26px" h="26px"/>
                      <Text fontSize="10px" fontWeight="700" pos="absolute" right="5px" bottom="5px" lineHeight="1">{levelStates.objectiveDataContainer.objective02.count}</Text>
                    </>
                  }
                </Box>

                <Box w="46px" h="46px" border="1px solid #fff" mx="2.5px" borderRadius="8px" pos="relative" display="flex" justifyContent="center" alignItems="center" userSelect="none" sx={{"&:first-of-type": {ml: 0}}}>
                  {
                    levelStates.objectiveDataContainer.objective03.type !== "None" &&
                    <>
                      <ImageDynamic name={levelStates.objectiveDataContainer.objective03.type2} motion="scale" w="26px" h="26px"/>
                      <Text fontSize="10px" fontWeight="700" pos="absolute" right="5px" bottom="5px" lineHeight="1">{levelStates.objectiveDataContainer.objective03.count}</Text>
                    </>
                  }
                </Box>
              </Box>

            </Box>
          </Box>

          <Box w="49%" h="100%" display="flex" flexDir="column" justifyContent="space-between">
            <Box w="100%" h="30%" border="2px solid #fff" borderRadius="8px" pos="relative" display="flex" alignItems="center">
              <Button position="absolute" top="0" right="0" p="0" w="15px" h="15px" minW="initial" bg="transparent" _hover={{bg: "transparent"}} _active={{bg: "transparent", transform: "scale(.9)"}} onClick={()=>removeMoveAndTime()}>
                <Icon as={CloseIcon} fontSize="10px"/>
              </Button>

              <Box fontSize="10px" px="16px">
                <Box display="flex">
                  <Text fontWeight="700" mr="2.5px">Move:</Text>
                  <Text fontWeight="400">{levelStates.objectiveDataContainer.moveObjective === 0 ? "-" : levelStates.objectiveDataContainer.moveObjective}</Text>
                </Box>
                <Box display="flex">
                  <Text fontWeight="700" mr="2.5px">Time:</Text>
                  <Text fontWeight="400">{levelStates.objectiveDataContainer.timeObjective === 0 ? "-" : levelStates.objectiveDataContainer.timeObjective}</Text>
                </Box>
              </Box>
            </Box>

            <Box w="100%" h="30%" border="2px solid #fff" borderRadius="8px" pos="relative" display="flex" alignItems="center">
              <Button position="absolute" top="0" right="0" p="0" w="15px" h="15px" minW="initial" bg="transparent" _hover={{bg: "transparent"}} _active={{bg: "transparent", transform: "scale(.9)"}} onClick={()=>removeMateriaAndExperience()}>
                <Icon as={CloseIcon} fontSize="10px"/>
              </Button>

              <Box fontSize="10px" px="16px">
                <Box display="flex">
                  <Text fontWeight="700" mr="2.5px">Materia:</Text>
                  <Text fontWeight="400">{levelStates.gold}</Text>
                </Box>
                <Box display="flex">
                  <Text fontWeight="700" mr="2.5px">Experience:</Text>
                  <Text fontWeight="400">{levelStates.experience}</Text>
                </Box>
              </Box>
            </Box>

            <Box w="100%" h="30%" border="2px solid #fff" borderRadius="8px" pos="relative" display="flex" alignItems="center">
              <Button position="absolute" top="0" right="0" p="0" w="15px" h="15px" minW="initial" bg="transparent" _hover={{bg: "transparent"}} _active={{bg: "transparent", transform: "scale(.9)"}} onClick={()=>console.log("remove")}>
                <Icon as={CloseIcon} fontSize="10px"/>
              </Button>

              <Box fontSize="10px" px="16px">
                <Box>
                  <Text fontWeight="700">Active Humanoid Blockers:</Text>
                  <Text fontWeight="400">Cover, Breakable</Text>
                </Box>
              </Box>
            </Box>

          </Box>
        </Box>

        <Box mt="52px">
          <Box display="flex" flexDir="column" w="fit-content" m="auto" flexWrap="wrap">
            {
              [...Array(levelStates.ySize)].map((row,i)=>
                <Box key={i} display="flex">
                  {
                    levelStates.tileDataContainers.filter((cell)=>cell.column === i && cell.row < levelStates.xSize).map((cell,k)=>                    
                      <Box key={k} w="46px" h="46px" m="2px" pos="relative" overflow="hidden" border={cell.blockerData[0].type !== "Void" ? "1px solid #fff" : "1px solid #333"} filter={cell.blockerData[0].type === "Void" ? "grayscale(80%)" : ""} borderBottomStyle={cell.isBottomEndTile ? "dotted" : "solid"} borderRadius="8px" borderBottomLeftRadius={cell.isBottomEndTile ? "2px" : "8px"} borderBottomRightRadius={cell.isBottomEndTile ? "2px" : "8px"} transition="200ms" onClick={()=>changeCellDataFunc(cell.row, cell.column)}>
                        {
                          cell.squareData.gameElementType2 !== "none" &&
                          <Box pos="absolute" zIndex={1} top="50%" left="50%" display="flex" justifyContent="center" sx={{transform: "translate(-50%,-50%)"}} w="100%" h="100%">
                            <ImageDynamic name={cell.squareData.gameElementType2} motion={cell.squareData.gameElementType.toLowerCase().includes("top") ? "top" : cell.squareData.gameElementType.toLowerCase().includes("bottom") ? "bottom" : cell.squareData.gameElementType.toLowerCase().includes("left") ? "left" : cell.squareData.gameElementType.toLowerCase().includes("right") ? "right" : "scale"} w="100%"  h="100%"/>
                          </Box>
                        }

                        {
                          editorStates.cellsLevel >= 1 && cell.itemData.type2 !== "none" &&
                          <Box pos="absolute" zIndex={2} top="50%" left="50%" display="flex" justifyContent="center" sx={{transform: "translate(-50%,-50%)"}} w="30px" h="30px">
                            <ImageDynamic name={cell.itemData.type2} motion="scale" w="100%" h="100%"/>
                          </Box>
                        }

                        {
                          editorStates.cellsLevel === 2 && cell.blockerData[0].type2 !== "none" && cell.blockerData[0].type2 !== "void" &&
                          <Box pos="absolute" zIndex={3} top="50%" left="50%" display="flex" justifyContent="center" sx={{transform: "translate(-50%,-50%)"}} w="45px" h="45px">
                            <ImageDynamic name={cell.blockerData[0].type2} blockerState={cell.blockerData[0].blockerState} motion="scale" w="100%" h="100%"/>
                          </Box>
                        }
                      </Box>
                    )
                  }
                </Box>
              ).sort((a,b) => a > b ? 1 : -1)
            }
          </Box>
        </Box>
        
      </Box>
    </Box>
  )
}

export default Level