const powerUps = [
  [
    {
      name: "Pre-Defined",
      name2: "preDefined",
    },
    {
      name: "Continues",
      name2: "continues",
    }
  ]
]

const powerUps2 = [
  [
    {
      name: "Almight 1",
      name2: "almight1",
    },
    {
      name: "Almight 2",
      name2: "almight2",
    },
    {
      name: "Almight 3",
      name2: "almight3",
    }
  ]
]

export {powerUps2}
export default powerUps