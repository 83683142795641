import {useState, FC} from "react"
import {Link, useLocation} from "react-router-dom"

import { useSelector } from "react-redux"
import { InterfaceAllSlices } from "../../typescript/interfaceAllSlices"

import {Box, Image, Text, Icon} from "@chakra-ui/react"
import {HiOutlineUserCircle as UserIcon} from "react-icons/hi"
import {BsChevronDown as DownIcon} from "react-icons/bs"
import {motion} from "framer-motion"

import Logo from "../../assets/images/logo/logo-minimalist.png"

const navbarItems = [
  {
    name: "Home",
    url: "/"
  },
  {
    name: "Levels",
    url: "/levels"
  },
  {
    name: "Blockers",
    url: "/blockers"
  },
  {
    name: "Spawners",
    url: "/spawners"
  },
  {
    name: "Users",
    url: "/users"
  }
]

const userDropdownItems = [
  {
    name: "Change Password",
    url: "/change-password"
  },
  {
    name: "Logout",
    url: "/logout"
  }
]

const Header:FC = () => {

  const location = useLocation()
  
  const userStates = useSelector((state:InterfaceAllSlices) => state.user)

  const [showDropdown, setShowDropdown] = useState(false)
  
  return (
    <Box h="60px" w="100%" borderTop="1px solid #1E2D3D" borderBottom="1px solid #1E2D3D" position="absolute" top="0" zIndex="10">
      <Box h="100%" w="100%" maxW="1920px" margin="auto" borderLeft="1px solid" borderRight="1px solid" borderColor="#1E2D3D" display="flex" flexDir="row">
        <Box display="flex" alignItems="center" width="390px" borderRight="1px solid #1E2D3D">
          <Link to="/" style={{display: "flex", alignItems: "center", width: "100%", height: "100%", paddingLeft: "25px"}}>
            <Image src={Logo} width="40px" height="44px" marginRight="15px" userSelect="none"/>
            <Text userSelect="none" fontSize="18px"
              sx={{
                background: "-webkit-linear-gradient(0deg, #5671A6 15%, #B2CAD8 60%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent"
              }}>Level Editor | Nora's Ark</Text>
          </Link>
        </Box>
        <Box display="flex" flexDir="row">
          {
            navbarItems.map((navbarItem,i)=>
              <Box key={i} borderRight="1px solid #1E2D3D" userSelect="none" sx={{"&:hover a:not(.active)": {color: "#92afcc!important"}, "&:active a:not(.active)": {transform: "scale(.9)"}}}>
                <Link to={navbarItem.url} style={{display: "flex", alignItems: "center", width: "100%", height: "100%", padding: "0 40px", fontSize: "18px", transition: "200ms", color: [...location.pathname.split("/")][1] === [...navbarItem.url.split("/")][1] ? "#fff" : "#607B96"}} className={(location.pathname === navbarItem.url || `/${location.pathname.split("/")[1]}` === navbarItem.url) ? "active" : ""}>{navbarItem.name}</Link>
              </Box>
            )
          }
        </Box>
        <Box width="270px" borderLeft="1px solid #1E2D3D" marginLeft="auto" display="flex" alignItems="center" justifyContent="center">
          <Box display="flex" justifyContent="center" alignItems="center" w="100%" h="100%" cursor="pointer" userSelect="none" _hover={{"& svg, & p": {color: "#92afcc!important"}}} _active={{"& svg:last-child": {transform: "rotate(90deg)"}}} transition="200ms" onClick={() => setShowDropdown(true)}>
            <Icon as={UserIcon} fontSize="28px" color="#607B96" marginRight="15px" transition="200ms"/>
            <Text fontSize="18px" fontWeight="400" color="#607B96" transition="200ms">{userStates.name ? userStates.name : userStates.email}</Text>
            <Icon as={DownIcon} fontSize="24px" color="#607B96" marginLeft="15px" style={{rotate: showDropdown ? "180deg" : "0deg "}} transition="200ms"/>
          </Box>

          {
            showDropdown &&
            <>
              <Box as={motion.div} initial={{opacity: 0}} animate={{opacity: 1}} transition="0.1s" backgroundColor="#000000aa" position="fixed" top="0" left="0" width="100vw" height="100vh" zIndex="2" onClick={()=>setShowDropdown(false)}/>
              <Box as={motion.div} initial={{translateY: 20}} animate={{translateY: 0}} position="absolute" top="75px" zIndex="2" backgroundColor="#fff" borderRadius="10px" textAlign="center" overflow="hidden" userSelect="none">
                {
                  userDropdownItems.map((userDropdownItem,i)=>
                    <DropdownText key={i} to={userDropdownItem.url} onClick={()=>setShowDropdown(false)}>
                      {userDropdownItem.name}
                    </DropdownText>
                  )
                }
              </Box>
            </>
          }
        </Box>
      </Box>
    </Box>
  )
}

interface DropdownTextProps {
  children: string
  to: string
  onClick: ()=>void
}

const DropdownText:FC<DropdownTextProps> = (props) => {
  return (
    <Text fontWeight="700" fontSize="14px" color="#000" cursor="pointer" _hover={{backgroundColor: "#CCE3E7"}} onClick={props.onClick}>
      <Link to={props.to} style={{display: "block", padding: "10px", width: "270px"}}>{props.children}</Link>
    </Text>
  )
}

export default Header