import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import axios from "../axios"
import axiosOut from "axios"
import FormData from "form-data"
import uuid from "react-uuid"

import { Box, Flex, Image, Text } from "@chakra-ui/react"
import { toast } from "react-toastify"

import InputButtonIcon from "../Components/Inputs/InputButtonIcon"
import InputFile from "../Components/Inputs/InputFile"
import InputNumber from "../Components/Inputs/InputNumber"
import InputSelectText from "../Components/Inputs/InputSelectText"
import InputSlider from "../Components/Inputs/InputSlider"
import InputText from "../Components/Inputs/InputText"

const blockerTypes = [
  [
    {
      name: "Breakable Blockers", 
      name2: "0"
    },
    {
      name: "Collectible Blockers", 
      name2: "1" 
    },
    {
      name: "Cover Blockers", 
      name2: "2" 
    },
    {
      name: "Humanoid Blockers", 
      name2: "3" 
    },
    {
      name: "Random Humanoid Blockers", 
      name2: "4" 
    }
  ]
]

const spawnerTypes = [
  [
    {
      name: "Color Stones", 
      name2: "0" 
    },
    {
      name: "Power Stones", 
      name2: "1" 
    },
    {
      name: "Droppable", 
      name2: "2" 
    },
  ]
]

const Blocker = (props:any) => {

  const [searchParams, setSearchParams] = useSearchParams()
  const queryId = searchParams.get("id")
  const queryType = searchParams.get("type")
  
  const navigate = useNavigate()
  const location = useLocation()
  const [purpose, setPurpose] = useState(location.pathname.split("/")[1]) //blockers or spawners
  const [purpose2, setPurpose2] = useState(location.pathname.split("/")[2].split("-")[0]) //new or edit
  
  const [type, setType] = useState("")
  const [name, setName] = useState("")
  const [id, setId] = useState(0)
  const [state, setState] = useState(3)
  const [selectedBiome, setSelectedBiome] = useState(purpose2 === "new" ? location.state.selectedBiome : null)
  const [imageFile, setImageFile] = useState<File>()
  const [imageShow, setImageShow] = useState("")
  const [imageSend, setImageSend] = useState("")

  const [inputsLoading, setInputsLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState({
    button1: false,
    button2: false,
    button3: false,
  })

  useEffect(()=>{

    if(purpose2 === "edit"){

      setInputsLoading(true)
      setButtonLoading({button1: true, button2: true, button3: true})
 
      axios.get(`/${purpose}?id=${queryId}&type=${queryType}`).then((res)=>{
        setInputsLoading(false)
        setButtonLoading({button1: false, button2: false, button3: false})
        setType(res.data.type)
        setName(res.data.name)
        setId(res.data.id)
        setState(res.data.state)
        setSelectedBiome(res.data.biome)
      }).catch((err)=>{
        toast.error(err.response.data.message)
        setInputsLoading(false)
        setButtonLoading({button1: false, button2: false, button3: false})
      })

    }
    
  },[])

  function getImageBase64(){
    if(imageFile){
      const reader = new FileReader()

      reader.onload = () => {
        
        const src = reader.result as string
        setImageShow(src)
      }

      reader.readAsDataURL(imageFile)

    }
  }

  function getImageBinary(){
    if(imageFile){
      const reader = new FileReader()

      reader.onload = () => {
        
        const binary = reader.result as string
        setImageSend(binary)
      }

      reader.readAsBinaryString(imageFile)

    }
  }

  useEffect(()=>{
    getImageBase64()
    getImageBinary()
  },[imageFile])

  function newAsset(){

    setButtonLoading(prev=>({...prev, button1: true}))
    const imageName = `${uuid()}.png`
    
    axios.post(`/${purpose}`, {
      id: id,
      name: name,
      subName: "subName",
      type: Number(type),
      image: imageName,
      biome: selectedBiome,
      state: purpose === "blockers" ? state : null
    }).then((res)=>{

      setButtonLoading(prev=>({...prev, button1: false}))

      const imageFileBlob = imageFile?.slice(0, imageFile.size, "image/png")
      const newImageFile = new File([imageFileBlob ? imageFileBlob : ""], imageName, {type: "image/png"})

      const formData = new FormData()
      formData.append("file", imageFile ? newImageFile : "no-image", imageName)
      
      axiosOut({
        url: res.data,
        method: "put",
        headers: { 
          "Content-Type": newImageFile?.type
        },
        data : newImageFile
      }).then((res2)=>{
        toast.success(res2.statusText)
        navigate(`/${purpose}`)
      }).catch((err2)=>{

        toast.error(err2.response.data.message)
        setButtonLoading(prev=>({...prev, button1: false}))

      })

    }).catch((err)=>{
      toast.error(err.response.data.message)
      setButtonLoading(prev=>({...prev, button1: false}))
    })
  }

  function updateAsset(){

    setButtonLoading(prev=>({...prev, button1: true}))

    const randomNumber1 = Math.floor(Math.random() * 100)
    const randomNumber2 = Math.floor(Math.random() * 100)

    axios.put(`/${purpose}`, {
      id: id,
      name: name,
      subName: "subName",
      type: Number(type),
      image: `${id+type+selectedBiome+name+randomNumber1+randomNumber2}`,
      biome: selectedBiome,
      state: purpose === "blockers" ? state : null
    }).then((res)=>{
      toast.success(res.statusText)
      setButtonLoading(prev=>({...prev, button1: false}))
      navigate(`/${purpose}`)
    }).catch((err)=>{
      toast.error(err.response.data.message)
      setButtonLoading(prev=>({...prev, button1: false}))
    })
  }

  function removeAsset(){

    setButtonLoading(prev=>({...prev, button2: true}))

    const body = {
      id: id,
      type: Number(type)
    }

    axios.delete(`/${purpose}`, {data: body}).then((res)=>{
      toast.success(res.statusText)
      setButtonLoading(prev=>({...prev, button2: false}))
      navigate(-1)
    }).catch((err)=>{
      toast.error(err.response.data.message)
      setButtonLoading(prev=>({...prev, button2: false}))
    })
  }

  useEffect(()=>{
    if(purpose2 === "new" && !selectedBiome){
      navigate(`/${purpose}`)
    }
  },[])
  
  return (
    <Box pt="130px" w="1055px" display="flex" mx="auto">
      <Box display="flex" flexDir="column" w="520px">
        <Box mb="10px" color="#607B96" display="flex">
          <Text mr="10px">Biome:</Text>
          <Text textTransform="capitalize">{selectedBiome}</Text>
        </Box>
        <InputSelectText label="Type" loading={inputsLoading} placeholder="Select..." value={type ? (purpose === "blockers" ? blockerTypes[0][Number(type)].name : purpose === "spawners" ? spawnerTypes[0][Number(type)].name : "") : ""} width="100%" dropdownTexts={purpose === "blockers" && selectedBiome === "all" ? [[blockerTypes[0][3],blockerTypes[0][4]]] : purpose === "blockers" && selectedBiome !== "all" ? [[blockerTypes[0][0],blockerTypes[0][1],blockerTypes[0][2]]] : purpose === "spawners" && selectedBiome === "all" ? [[spawnerTypes[0][0],spawnerTypes[0][1]]] : purpose === "spawners" && selectedBiome !== "all" ? [[spawnerTypes[0][2]]] : [[]]} onClick={(e)=>setType(e.target.dataset.text2)}/>
        <Box display="flex" w="100">
          <InputText label="Name" biggerLabel type="text" value={name} loading={inputsLoading} sx={{w: "77%", mr: "auto"}} onChange={(e)=>setName(e.target.value)}/>
          <InputNumber label="ID" min={0} value={id} loading={inputsLoading} onChange={(val)=>setId(Number(val))}/>
        </Box>
        {
          purpose === "blockers" && <InputSlider label="State" biggerLabel min={1} max={3} width="100%" value={state} onChange={(val)=>setState(Number(val))}/>
        }
        <InputFile loading={inputsLoading} setImageFile={setImageFile}/>
      </Box>

      <Box ml="auto">
        <Flex justifyContent="center" alignItems="center" w="300px" h="300px" mb="16px" border="1px solid #fff" borderRadius="15px">
          <Image src={imageShow} w="auto" h="216px" sx={{"WebkitUserDrag": "none"}}/>
        </Flex>
        
        <Flex justifyContent="end">
          <InputButtonIcon icon="back" size="md" loading={buttonLoading.button3} sx={{mr: "10px"}} onClick={()=>navigate(-1)}/>
          {
            purpose2 === "edit" && <InputButtonIcon icon="remove" size="md" loading={buttonLoading.button2} sx={{mr: "10px"}} onClick={()=>removeAsset()}/>
          }
          <InputButtonIcon icon="save" size="md" loading={buttonLoading.button1} onClick={()=> purpose2 === "new" ? newAsset() : updateAsset()}/>
        </Flex>
      </Box>
    </Box>
  )
}

export default Blocker