import biomPlain from "../../assets/images/bioms/plain.png"
import biomForest from "../../assets/images/bioms/forest.png"
import biomMountain from "../../assets/images/bioms/mountain.png"
import biomAquatic from "../../assets/images/bioms/aquatic.png"
import biomArctic from "../../assets/images/bioms/arctic.png"
import biomDesert from "../../assets/images/bioms/desert.png"

const biomes = [
  {
    name: "Plain",
    img: biomPlain,
    status: true
  },
  {
    name: "Forest",
    img: biomForest,
    status: true
  },
  {
    name: "Mountain",
    img: biomMountain,
    status: true
  },
  {
    name: "Aquatic",
    img: biomAquatic,
    status: false
  },
  {
    name: "Arctic",
    img: biomArctic,
    status: false
  },
  {
    name: "Desert",
    img: biomDesert,
    status: false
  }
]

export default biomes