import axios from "axios"
import jwtDecode from "jwt-decode"
import { InterfaceUserSlice } from "../../typescript/interfaceUserSlice"

interface UserData{
  email: string
  password: string
}

const login = async (userData:UserData) => {
  const response = await axios.post("https://editor-api.nora.lostctrlbackendservices.com/dev/users/authentication", userData)

  if(response.data.token){

    const decodedToken:InterfaceUserSlice = jwtDecode(response.data.token)

    const user = {
      id: decodedToken.id,
      name: decodedToken.name,
      email: decodedToken.email,
      role: decodedToken.role, 
      token: response.data.token,
      exp: decodedToken.exp,
    }
    
    return user

  } 
  
}

const authService = {
  login
}

export default authService