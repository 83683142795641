import React, { FC } from "react"
import {Box, Text, Image, Slider, SliderTrack, SliderFilledTrack, SliderThumb} from "@chakra-ui/react"
import ImageDynamic from "../ImageDynamic"

interface Props {
  margin?: string,
  width?: string
  divWidth?: string
  label?: string,
  biggerLabel?: boolean
  img?: string,
  min?: number
  max?: number
  value: number,
  onChange?: (e:any) => void
}

const InputSlider:FC<Props> = (props:Props) => {  
  
  return (
    <Box margin={props.margin ? props.margin : "0 0 20px 0"} display="flex" flexDirection={props.img ? "row" : "column"} alignItems={props.img ? "center" : "initial"} width={props.width ? props.width : "200px"}>
      {
        props.img && <Box w="60px"><ImageDynamic name={props.img} motion="scale" h="40px" w="auto"/></Box>
      }

      {
        props.label && !props.biggerLabel && <Text fontSize="12px" fontWeight="700" marginBottom="7.5px" color="#607B96">{props.label}: {props.value}</Text>
      }

      {
        props.label && props.biggerLabel && <Text fontSize="16px" fontWeight="600" marginBottom="7.5px" color="#607B96" userSelect="none">{props.label}: {props.value}</Text>
      }

      <Slider width={props.img ? "calc(100% - (60px + 50px))" : "100%"} mr="auto" min={props.min} max={props.max} value={props.value} onChange={props.onChange}>
        <SliderTrack height="10px" backgroundColor="transparent" border="1px solid #fff" borderRadius="8px">
          <SliderFilledTrack backgroundColor="#607B96"/>
        </SliderTrack>
        <SliderThumb backgroundColor="#607B96" width="16px" height="16px" border="1px solid #fff!important" boxShadow="none!important" />
      </Slider>

      {
        props.img && <Text textAlign="right" color="#607B96" fontWeight="400" fontSize="16px" userSelect="none">{props.value}</Text>
      }
    </Box>
  )
}

export default InputSlider