import { configureStore, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit"
import userReducer from "./userSlice"
import levelReducer from "./levelSlice"
import editorReducer from "./editorSlice"
import popupManagerReducer from "./popupManagerSlice"

import storage from "redux-persist/lib/storage"
import {persistReducer} from "redux-persist"

const persistConfig = {
  key: "allRedux",
  storage
}

const combinedReducers = combineReducers({
  user: userReducer,
  level: levelReducer,
  editor: editorReducer,
  popupManager: popupManagerReducer,
})

const persistedReducer = persistReducer(persistConfig, combinedReducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false
  })
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store