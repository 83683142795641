import teleportTopEnter from "../images/cellFeatures/teleport/teleportTopEnter.png"
import teleportLeftEnter from "../images/cellFeatures/teleport/teleportLeftEnter.png"
import teleportBottomEnter from "../images/cellFeatures/teleport/teleportBottomEnter.png"
import teleportRightEnter from "../images/cellFeatures/teleport/teleportRightEnter.png"
import teleportTopExit from "../images/cellFeatures/teleport/teleportTopExit.png"
import teleportLeftExit from "../images/cellFeatures/teleport/teleportLeftExit.png"
import teleportBottomExit from "../images/cellFeatures/teleport/teleportBottomExit.png"
import teleportRightExit from "../images/cellFeatures/teleport/teleportRightExit.png"

import conveyorTop from "../images/cellFeatures/conveyor/conveyorTop.png"
import conveyorLeft from "../images/cellFeatures/conveyor/conveyorLeft.png"
import conveyorBottom from "../images/cellFeatures/conveyor/conveyorBottom.png"
import conveyorRight from "../images/cellFeatures/conveyor/conveyorRight.png"

import wallBottom from "../images/cellFeatures/wall/wallBottom.png"
import wallLeft from "../images/cellFeatures/wall/wallLeft.png"
import wallTop from "../images/cellFeatures/wall/wallTop.png"
import wallRight from "../images/cellFeatures/wall/wallRight.png"
import wallBottomLeft from "../images/cellFeatures/wall/wallBottomLeft.png"
import wallBottomRight from "../images/cellFeatures/wall/wallBottomRight.png"
import wallTopLeft from "../images/cellFeatures/wall/wallTopLeft.png"
import wallTopRight from "../images/cellFeatures/wall/wallTopRight.png"
import wallU from "../images/cellFeatures/wall/wallU.png"
import wallC from "../images/cellFeatures/wall/wallC.png"
import wallCReverse from "../images/cellFeatures/wall/wallCReverse.png"
import wallUReverse from "../images/cellFeatures/wall/wallUReverse.png"
import wallO from "../images/cellFeatures/wall/wallO.png"
import wallParallelHorizontal from "../images/cellFeatures/wall/wallParallelHorizontal.png"
import wallParallelVertical from "../images/cellFeatures/wall/wallParallelVertical.png"

export default [
  {
    name: "Teleport Top Enter",
    name2: "teleportTopEnter",
    type: "teleport",
    imgUrl: teleportTopEnter
  },
  {
    name: "Teleport Left Enter",
    name2: "teleportLeftEnter",
    type: "teleport",
    imgUrl: teleportLeftEnter
  },
  {
    name: "Teleport Bottom Enter",
    name2: "teleportBottomEnter",
    type: "teleport",
    imgUrl: teleportBottomEnter
  },
  {
    name: "Teleport Right Enter",
    name2: "teleportRightEnter",
    type: "teleport",
    imgUrl: teleportRightEnter
  },
  {
    name: "Teleport Top Exit",
    name2: "teleportTopExit",
    type: "teleport",
    imgUrl: teleportTopExit
  },
  {
    name: "Teleport Left Exit",
    name2: "teleportLeftExit",
    type: "teleport",
    imgUrl: teleportLeftExit
  },
  {
    name: "Teleport Bottom Exit",
    name2: "teleportBottomExit",
    type: "teleport",
    imgUrl: teleportBottomExit
  },
  {
    name: "Teleport Right Exit",
    name2: "teleportRightExit",
    type: "teleport",
    imgUrl: teleportRightExit
  },
  {
    name: "Conveyor Top",
    name2: "conveyorTop",
    type: "conveyor",
    imgUrl: conveyorTop
  },
  {
    name: "Conveyor Left",
    name2: "conveyorLeft",
    type: "conveyor",
    imgUrl: conveyorLeft
  },
  {
    name: "Conveyor Bottom",
    name2: "conveyorBottom",
    type: "conveyor",
    imgUrl: conveyorBottom
  },
  {
    name: "Conveyor Right",
    name2: "conveyorRight",
    type: "conveyor",
    imgUrl: conveyorRight
  },
  {
    name: "Wall Bottom",
    name2: "wallBottom",
    type: "wall",
    imgUrl: wallBottom
  },
  {
    name: "Wall Left",
    name2: "wallLeft",
    type: "wall",
    imgUrl: wallLeft
  },
  {
    name: "Wall Top",
    name2: "wallTop",
    type: "wall",
    imgUrl: wallTop
  },
  {
    name: "Wall Right",
    name2: "wallRight",
    type: "wall",
    imgUrl: wallRight
  },
  {
    name: "Wall Bottom Left",
    name2: "wallBottomLeft",
    type: "wall",
    imgUrl: wallBottomLeft
  },
  {
    name: "Wall Bottom Right",
    name2: "wallBottomRight",
    type: "wall",
    imgUrl: wallBottomRight
  },
  {
    name: "Wall Top Left",
    name2: "wallTopLeft",
    type: "wall",
    imgUrl: wallTopLeft
  },
  {
    name: "Wall Top Right",
    name2: "wallTopRight",
    type: "wall",
    imgUrl: wallTopRight
  },
  {
    name: "Wall U",
    name2: "wallU",
    type: "wall",
    imgUrl: wallU
  },
  {
    name: "Wall C",
    name2: "wallC",
    type: "wall",
    imgUrl: wallC
  },
  {
    name: "Wall C Reverse",
    name2: "wallCReverse",
    type: "wall",
    imgUrl: wallCReverse
  },
  {
    name: "Wall U Reverse",
    name2: "wallUReverse",
    type: "wall",
    imgUrl: wallUReverse
  },
  {
    name: "Wall O",
    name2: "wallO",
    type: "wall",
    imgUrl: wallO
  },
  {
    name: "Wall Parallel Horizontal",
    name2: "wallParallelHorizontal",
    type: "wall",
    imgUrl: wallParallelHorizontal
  },
  {
    name: "Wall Parallel Vertical",
    name2: "wallParallelVertical",
    type: "wall",
    imgUrl: wallParallelVertical
  }
]