import React, {FC, useEffect, useState} from "react"

import { Box, Image, SystemStyleObject } from "@chakra-ui/react"
import {motion, AnimatePresence} from "framer-motion"

import blockers from "../assets/fakeData/blockers"
import spawners from "../assets/fakeData/spawners"
import cellFeatures from "../assets/fakeData/cellFeatures"

interface Props {
  sx?: SystemStyleObject
  motion?: "scale" | "opacity" | "top" | "right" | "bottom" | "left"
  w: string
  h: string
  name: string
  blockerState?: number
}

const ImageDynamic:FC<Props> = (props) => {

  const [img, setImg] = useState<string>("")
  
  function getStone(){

    blockers.map((blocker,i)=>{
      if(blocker.name2 === props.name){
        if(blocker.blockerState === props.blockerState){
          setImg(blocker.imgUrl)
        }
      }
    })

    spawners.map((spawner,i)=>{
      if(spawner.name2 === props.name){
        setImg(spawner.image)
      }
    })

    cellFeatures.map((cellFeature,i)=>{
      if(cellFeature.name2 === props.name){
        setImg(cellFeature.imgUrl)
      }
    })

  }

  useEffect(()=>{
    getStone()
  },[props.name, props.blockerState])

  const motionVariants = {
    hidden: {
      transform: props.motion === "scale" ? "scale(0)" : props.motion === "top" ? "translateY(-10px)" : props.motion === "bottom" ? "translateY(10px)" : props.motion === "left" ? "translateX(-10px)" : props.motion === "right" ? "translateX(10px)" : "",
      opacity: props.motion === "opacity" ? 0 : 1,
    },
    visible: {
      transform: props.motion === "scale" ? "scale(1)" : props.motion === "top" ? "translateY(-0px)" : props.motion === "bottom" ? "translateY(0px)" : props.motion === "left" ? "translateX(0px)" : props.motion === "right" ? "translateX(0px)" : "",
      opacity: props.motion === "opacity" ? 1 : 1,
    }
  }
  
  return (
    <Box pos="relative" w={props.w} h={props.h} sx={{aspectRatio: "1", ...props.sx}}>

      <Image src={img} key={img} as={motion.img}
        variants={motionVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        pos="absolute" left="0" top="0" w="100%" h="100%" userSelect="none"
      />

    </Box>
  )
}

export default ImageDynamic