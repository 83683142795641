import breakableBlockerPlain1 from "../images/blockers/breakableBlockers/plain/1.png"
import breakableBlockerPlain2 from "../images/blockers/breakableBlockers/plain/2.png"
import breakableBlockerPlain3 from "../images/blockers/breakableBlockers/plain/3.png"

import breakableBlockerForest1 from "../images/blockers/breakableBlockers/forest/1.png"
import breakableBlockerForest2 from "../images/blockers/breakableBlockers/forest/2.png"
import breakableBlockerForest3 from "../images/blockers/breakableBlockers/forest/3.png"

import breakableBlockerMountain1 from "../images/blockers/breakableBlockers/mountain/1.png"
import breakableBlockerMountain2 from "../images/blockers/breakableBlockers/mountain/2.png"
import breakableBlockerMountain3 from "../images/blockers/breakableBlockers/mountain/3.png"

import collectibleBlockerPlain1 from "../images/blockers/collectibleBlockers/plain/1.png"
import collectibleBlockerPlain2 from "../images/blockers/collectibleBlockers/plain/2.png"
import collectibleBlockerPlain3 from "../images/blockers/collectibleBlockers/plain/3.png"

import collectibleBlockerForest1 from "../images/blockers/collectibleBlockers/forest/1.png"
import collectibleBlockerForest2 from "../images/blockers/collectibleBlockers/forest/2.png"
import collectibleBlockerForest3 from "../images/blockers/collectibleBlockers/forest/3.png"

import collectibleBlockerMountain1 from "../images/blockers/collectibleBlockers/mountain/1.png"
import collectibleBlockerMountain2 from "../images/blockers/collectibleBlockers/mountain/2.png"
import collectibleBlockerMountain3 from "../images/blockers/collectibleBlockers/mountain/3.png"

import coverBlockerPlain1 from "../images/blockers/coverBlockers/plain/1.png"
import coverBlockerPlain2 from "../images/blockers/coverBlockers/plain/2.png"

import coverBlockerForest1 from "../images/blockers/coverBlockers/forest/1.png"
import coverBlockerForest2 from "../images/blockers/coverBlockers/forest/2.png"

import coverBlockerMountain1 from "../images/blockers/coverBlockers/mountain/1.png"
import coverBlockerMountain2 from "../images/blockers/coverBlockers/mountain/2.png"

import humanoidBlocker1 from "../images/blockers/humanoidBlockers/1.png"
import humanoidBlocker2 from "../images/blockers/humanoidBlockers/2.png"
import humanoidBlocker3 from "../images/blockers/humanoidBlockers/3.png"

import randomHumanoidBlocker1 from "../images/blockers/randomHumanoidBlockers/1.png"
import randomHumanoidBlocker2 from "../images/blockers/randomHumanoidBlockers/2.png"
import randomHumanoidBlocker3 from "../images/blockers/randomHumanoidBlockers/3.png"

export default [
  {
    name: "Plain Statue",
    name2: "plainStatue",
    type: "breakableBlocker",
    biome: "plain",
    blockerState: 3,
    imgUrl: breakableBlockerPlain1
  },
  {
    name: "Plain Statue",
    name2: "plainStatue",
    type: "breakableBlocker",
    biome: "plain",
    blockerState: 2,
    imgUrl: breakableBlockerPlain2
  },
  {
    name: "Plain Statue",
    name2: "plainStatue",
    type: "breakableBlocker",
    biome: "plain",
    blockerState: 1,
    imgUrl: breakableBlockerPlain3
  },
  {
    name: "Plain Essence",
    name2: "plainEssence",
    type: "collectibleBlocker",
    biome: "plain",
    blockerState: 3,
    imgUrl: collectibleBlockerPlain1
  },
  {
    name: "Plain Essence",
    name2: "plainEssence",
    type: "collectibleBlocker",
    biome: "plain",
    blockerState: 2,
    imgUrl: collectibleBlockerPlain2
  },
  {
    name: "Plain Essence",
    name2: "plainEssence",
    type: "collectibleBlocker",
    biome: "plain",
    blockerState: 1,
    imgUrl: collectibleBlockerPlain3
  },
  {
    name: "Wheat",
    name2: "wheat",
    type: "coverBlocker",
    biome: "plain",
    blockerState: 2,
    imgUrl: coverBlockerPlain1
  },
  {
    name: "Wheat",
    name2: "wheat",
    type: "coverBlocker",
    biome: "plain",
    blockerState: 1,
    imgUrl: coverBlockerPlain2
  },
  {
    name: "Forest Statue",
    name2: "forestStatue",
    type: "breakableBlocker",
    biome: "forest",
    blockerState: 3,
    imgUrl: breakableBlockerForest1
  },
  {
    name: "Forest Statue",
    name2: "forestStatue",
    type: "breakableBlocker",
    biome: "forest",
    blockerState: 2,
    imgUrl: breakableBlockerForest2
  },
  {
    name: "Forest Statue",
    name2: "forestStatue",
    type: "breakableBlocker",
    biome: "forest",
    blockerState: 1,
    imgUrl: breakableBlockerForest3
  },
  {
    name: "Forest Essence",
    name2: "forestEssence",
    type: "collectibleBlocker",
    biome: "forest",
    blockerState: 3,
    imgUrl: collectibleBlockerForest1
  },
  {
    name: "Forest Essence",
    name2: "forestEssence",
    type: "collectibleBlocker",
    biome: "forest",
    blockerState: 2,
    imgUrl: collectibleBlockerForest2
  },
  {
    name: "Forest Essence",
    name2: "forestEssence",
    type: "collectibleBlocker",
    biome: "forest",
    blockerState: 1,
    imgUrl: collectibleBlockerForest3
  },
  {
    name: "Ground Leave",
    name2: "groundLeaeve",
    type: "coverBlocker",
    biome: "forest",
    blockerState: 2,
    imgUrl: coverBlockerForest1
  },
  {
    name: "Ground Leave",
    name2: "groundLeaeve",
    type: "coverBlocker",
    biome: "forest",
    blockerState: 1,
    imgUrl: coverBlockerForest2
  },
  {
    name: "Vase",
    name2: "vase",
    type: "breakableBlocker",
    biome: "mountain",
    blockerState: 3,
    imgUrl: breakableBlockerMountain1
  },
  {
    name: "Vase",
    name2: "vase",
    type: "breakableBlocker",
    biome: "mountain",
    blockerState: 2,
    imgUrl: breakableBlockerMountain2
  },
  {
    name: "Vase",
    name2: "vase",
    type: "breakableBlocker",
    biome: "mountain",
    blockerState: 1,
    imgUrl: breakableBlockerMountain3
  },
  {
    name: "Mountain Essence",
    name2: "mountainEssence",
    type: "collectibleBlocker",
    biome: "mountain",
    blockerState: 3,
    imgUrl: collectibleBlockerMountain1
  },
  {
    name: "Mountain Essence",
    name2: "mountainEssence",
    type: "collectibleBlocker",
    biome: "mountain",
    blockerState: 2,
    imgUrl: collectibleBlockerMountain2
  },
  {
    name: "Mountain Essence",
    name2: "mountainEssence",
    type: "collectibleBlocker",
    biome: "mountain",
    blockerState: 1,
    imgUrl: collectibleBlockerMountain3
  },
  {
    name: "Cloud",
    name2: "cloud",
    type: "coverBlocker",
    biome: "mountain",
    blockerState: 2,
    imgUrl: coverBlockerMountain1
  },
  {
    name: "Cloud",
    name2: "cloud",
    type: "coverBlocker",
    biome: "mountain",
    blockerState: 1,
    imgUrl: coverBlockerMountain2
  },
  {
    name: "Stone",
    name2: "stone",
    type: "humanoidBlocker",
    biome: "all",
    blockerState: 3,
    imgUrl: humanoidBlocker1
  },
  {
    name: "Stone",
    name2: "stone",
    type: "humanoidBlocker",
    biome: "all",
    blockerState: 2,
    imgUrl: humanoidBlocker2
  },
  {
    name: "Stone",
    name2: "stone",
    type: "humanoidBlocker",
    biome: "all",
    blockerState: 1,
    imgUrl: humanoidBlocker3
  },
  {
    name: "Non-Possessed",
    name2: "nonPossessed",
    type: "randomHumanoidBlocker",
    biome: "all",
    blockerState: 3,
    imgUrl: randomHumanoidBlocker1
  },
  {
    name: "Non-Possessed",
    name2: "nonPossessed",
    type: "randomHumanoidBlocker",
    biome: "all",
    blockerState: 2,
    imgUrl: randomHumanoidBlocker2
  },
  {
    name: "Non-Possessed",
    name2: "nonPossessed",
    type: "randomHumanoidBlocker",
    biome: "all",
    blockerState: 1,
    imgUrl: randomHumanoidBlocker3
  },
]
