import React, { FC, useState, useEffect } from "react"
import {Box, Text, Icon, Image} from "@chakra-ui/react"
import {BsChevronDown} from "react-icons/bs"
import {motion} from "framer-motion"

import InputSelectText from "./InputSelectText"
import InputNumber from "./InputNumber"
import ImageDynamic from "../ImageDynamic"

const sizeTexts = [
  [
    {
      name:"1x1",
      name2:"1x1"
    },
    {
      name:"1x2",
      name2:"1x2"
    },
    {
      name:"2x1",
      name2:"2x1"
    },
    {
      name:"2x2",
      name2:"2x2"
    },
    {
      name:"2x3",
      name2:"2x3"
    },
    {
      name:"3x2",
      name2:"3x2"
    },
    {
      name:"3x3",
      name2:"3x3"
    },
    {
      name:"3x4",
      name2:"3x4"
    },
    {
      name:"4x3",
      name2:"4x3"
    },
    {
      name:"4x4",
      name2:"4x4"
    }
  ]
]

interface Props {
  label?: string
  placeholder?: string
  hidePlaceholderIfValueExist?: boolean
  margin?: string
  width?: string
  dropdownItemsGrid?: 2 | 3 | 4
  dropdownItems: {name: string, name2: string, blockerState?: number}[]
  sizeValue?: string
  sizeOnClick?: (e:any) => void
  amountValue?: number
  amountOnChange?: (e:any) => void
  selectedMoves?: {value:boolean}[]
  selectedMovesOnClick?: (e:any) => void
  dontCloseOnChange?: boolean
  value?: string
  onClick: (e:any) => void
}

const InputSelectItem:FC<Props> = (props) => {

  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  useEffect(()=>{
    if(showDropdown === true && props.dontCloseOnChange !== true){
      setShowDropdown(false)
    }
  }, [props.value])
  
  return (
    <Box position="relative" width={props.width ? props.width : "270px"} margin={props.margin ? props.margin : "0 0 20px 0"}>
      {
        props.label &&
        <Text
          fontSize="16px"
          fontWeight="600"
          marginBottom="7.5px"
          color="#607B96"
          userSelect="none"
        >{props.label}</Text>
      }
      
      <Box display="flex" alignItems="center" padding="0 10px" position="relative" overflow="hidden" width="100%" height="35px" border="1px solid #fff" borderColor='#fff' borderRadius="8px" cursor="pointer" onClick={()=>setShowDropdown(!showDropdown)}>
        <Box pos="absolute" left="0" top="0" w="100%" h="100%" boxShadow="inset -60px 0 25px -20px #171717" zIndex={2}/>
        <Text userSelect="none">{props.value && props.hidePlaceholderIfValueExist ? props.value : props.placeholder}</Text>
        <Icon as={BsChevronDown} position="absolute" right="10px" top="50%" transform="translateY(-50%)" zIndex={3} style={{transform: showDropdown ? "rotate(180deg) translateY(50%)" : ""}} transition="200ms"/>
      </Box>
      
      {
        showDropdown &&
        <>
          <Box as={motion.div} initial={{opacity: 0}} animate={{opacity: 1}} transition="0.1s" backgroundColor="#000000aa" position="fixed" top="0" left="0" width="100vw" height="100vh" zIndex="3" onClick={()=>setShowDropdown(false)}/>
          <Box as={motion.div} initial={{translateY: 20}} animate={{translateY: 0}} position="absolute" top={props.label ? "75px" : "45px"} zIndex="4" backgroundColor="#171717" width="100%" borderRadius="10px" userSelect="none" display="flex" flexWrap="wrap" padding="14px" border="1px solid #fff">
            {
              props.dropdownItems.map((dropdownItem,i)=>
                <DropdownItem key={i} dropdownItemsGrid={props.dropdownItemsGrid} onClick={props.onClick} text={dropdownItem.name} text2={dropdownItem.name2} blockerstate={dropdownItem.blockerState}/>
              )
            }
            {
              (props.sizeValue || props.amountValue) &&
              <Box w="100%" mt="10px" display="flex">
                {
                  props.sizeValue && 
                  <Box w="48%">
                    <InputSelectText margin="0" width="100%" label="Size" placeholder="Select" value={props.sizeValue} dropdownTexts={sizeTexts} onClick={props.sizeOnClick}/>
                  </Box>
                }
                {
                  props.amountValue && 
                  <Box w="48%" ml="auto">
                    <InputNumber margin="0" divWidth="100%" label="Amount" value={props.amountValue} onChange={props.amountOnChange}/>
                  </Box>
                }
              </Box>
            }
            {
              props.selectedMoves && 
              <Box w="100%" mt="10px">
                <Text
                  fontSize="16px"
                  fontWeight="600"
                  marginBottom="7.5px"
                  color="#607B96"
                  userSelect="none"
                  display="inline-block"
                >Moves</Text>

                <Box display="flex" flexWrap="wrap">
                  {
                    props.selectedMoves.map(((selectedMove,i)=>
                      <Box key={i} w="20px" h="20px" display="flex" justifyContent="center" alignItems="center" border={selectedMove.value === true ? "1px solid #4BB34B" : "1px solid #fff"} m="1.5px" borderRadius="4px" cursor="pointer" transition="200ms" data-key={i} onClick={props.selectedMovesOnClick}>
                        <Text fontSize="10px" fontWeight="600" color={selectedMove.value === true ? "#4BB34B" : "#fff"} pointerEvents="none" transition="200ms">{i+1}</Text>
                      </Box>
                    ))
                  }
                </Box>
              </Box>
            }
          </Box>
        </>
      }
    </Box>
  )
}

interface DropdownItemProps {
  dropdownItemsGrid?: 2 | 3 | 4,
  text: string
  text2: string
  blockerstate?: number
  onClick: (e:any) => void
}

const DropdownItem:FC<DropdownItemProps> = (props) => {
  return (
    <Box width={props.dropdownItemsGrid === 2 ? "50%" : props.dropdownItemsGrid === 3 ? "33.33%" : props.dropdownItemsGrid === 4 ? "25%" : "33.33%"} padding="4px" display="flex" sx={{"&:nth-of-type(odd)": {justifyContent: props.dropdownItemsGrid === 2 ? "end" : "center"}, "&:nth-of-type(even)" : {justifyContent: props.dropdownItemsGrid === 2 ? "start" : "center"}}}>
      <Box display="flex" justifyContent="center" border="1px solid #fff" borderRadius="8px" cursor="pointer" p="16px" sx={{aspectRatio: "1"}} data-text={props.text} data-text2={props.text2} data-blockerstate={props.blockerstate} onClick={props.onClick}>
        <ImageDynamic name={props.text2} blockerState={props.blockerstate} motion={props.text.toLowerCase().includes("top") ? "top" : props.text.toLowerCase().includes("bottom") ? "bottom" : props.text.toLowerCase().includes("left") ? "left" : props.text.toLowerCase().includes("right") ? "right" : "scale"} w="auto" h="40px" sx={{pointerEvents: "none"}}/>
      </Box>
    </Box>
  )
}

export default InputSelectItem