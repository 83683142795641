import React, {useState, useEffect, ReactNode} from "react"
import { useSelector, useDispatch } from "react-redux"
import { changeClipboard } from "../../../redux/editorSlice"
import { InterfaceAllSlices } from "../../../typescript/interfaceAllSlices"
import { InterfaceBlocker } from "../../../typescript/interfaceBlocker"

import {Box} from "@chakra-ui/react"

import InputSelectItem from "../../Inputs/InputSelectItem"

import blockers from "../../../assets/fakeData/blockers"

const Blockers = () => {

  const levelStates = useSelector((state:InterfaceAllSlices) => state.level)
  const dispatch = useDispatch()

  const [breakableBlockersData, setBreakableBlockersData] = useState<InterfaceBlocker[]>([])
  const [collectibleBlockersData, setCollectibleBlockersData] = useState<InterfaceBlocker[]>([])
  const [coverBlockersData, setCoverBlockersData] = useState<InterfaceBlocker[]>([])
  const [humanoidBlockersData, setHumanoidBlockersData] = useState<InterfaceBlocker[]>([])
  const [randomHumanoidBlockersData, setRandomHumanoidBlockersData] = useState<InterfaceBlocker[]>([])

  const [breakableBlocker, setBreakableBlocker] = useState({name:"", size: "1x1"})
  const [collectibleBlocker, setCollectibleBlocker] = useState({name:"", size: "1x1"})
  const [coverBlocker, setCoverBlocker] = useState({name:"", size: "1x1"})
  const [humanoidBlocker, setHumanoidBlocker] = useState({name:"", size: "1x1"})
  const [randomHumanoidBlocker, setRandomHumanoidBlocker] = useState({name:"", size: "1x1", amount: 1})
  const [selectedMoves, setSelectedMoves] = useState([
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: true},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: true},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false},
    {value: false}
  ])

  function changeCell(i:number){
    const localSelectedMoves = [...selectedMoves]

    localSelectedMoves[i].value = !localSelectedMoves[i].value

    setSelectedMoves(localSelectedMoves)
  }

  function getBlockers(){

    const localBreakableBlockers:InterfaceBlocker[] = []
    const localCollectibleBlockers:InterfaceBlocker[] = []
    const localCoverBlockers:InterfaceBlocker[] = []
    const localHumanoidBlockers:InterfaceBlocker[] = []
    const localRandomHumanoidBlockers:InterfaceBlocker[] = []
    
    blockers.map((blocker,i)=>{

      if(blocker.type === "breakableBlocker" && blocker.biome === levelStates.biomeType.toLowerCase()){
        localBreakableBlockers.push(blocker)
      } else if(blocker.type === "collectibleBlocker" && blocker.biome === levelStates.biomeType.toLowerCase()){
        localCollectibleBlockers.push(blocker)
      } else if(blocker.type === "coverBlocker" && blocker.biome === levelStates.biomeType.toLowerCase()){
        localCoverBlockers.push(blocker)
      } else if(blocker.type === "humanoidBlocker" && blocker.biome === levelStates.biomeType.toLowerCase()){
        localHumanoidBlockers.push(blocker)
      } else if(blocker.type === "randomHumanoidBlocker" && blocker.biome === levelStates.biomeType.toLowerCase()){
        localRandomHumanoidBlockers.push(blocker)
      }
      
    })

    setBreakableBlockersData(localBreakableBlockers)
    setCollectibleBlockersData(localCollectibleBlockers)
    setCoverBlockersData(localCoverBlockers)
    setHumanoidBlockersData(localHumanoidBlockers)
    setRandomHumanoidBlockersData(localRandomHumanoidBlockers)
  }

  useEffect(()=>{
    getBlockers()
  },[])
  
  return (
    <Box pt="100px" w="fit-content" mx="auto">
      <InputSelectItem 
        placeholder="Breakable Blockers" 
        dropdownItems={breakableBlockersData} 
        sizeValue={breakableBlocker.size} 
        sizeOnClick={(e)=>setBreakableBlocker({name: breakableBlocker.name, size: e.target.dataset.text})} 
        dontCloseOnChange={true}
        value={breakableBlocker.name} 
        onClick={(e)=>dispatch(changeClipboard({target: 2, type: e.target.dataset.text, type2: e.target.dataset.text2, blockerState: Number(e.target.dataset.blockerstate)}))}
      />

      <InputSelectItem 
        placeholder="Collectible Blockers" 
        dropdownItems={collectibleBlockersData} 
        sizeValue={collectibleBlocker.size} 
        sizeOnClick={(e)=>setCollectibleBlocker({name: collectibleBlocker.name, size: e.target.dataset.text})} 
        dontCloseOnChange={true}
        value={collectibleBlocker.name} 
        onClick={(e)=>dispatch(changeClipboard({target: 2, type: e.target.dataset.text, type2: e.target.dataset.text2, blockerState: Number(e.target.dataset.blockerstate)}))}
      />

      <InputSelectItem 
        placeholder="Cover Blockers" 
        dropdownItems={coverBlockersData} 
        sizeValue={coverBlocker.size} 
        sizeOnClick={(e)=>setCoverBlocker({name: coverBlocker.name, size: e.target.dataset.text})} 
        dontCloseOnChange={true}
        value={coverBlocker.name} 
        onClick={(e)=>dispatch(changeClipboard({target: 2, type: e.target.dataset.text, type2: e.target.dataset.text2, blockerState: Number(e.target.dataset.blockerstate)}))}
      />

      <InputSelectItem 
        placeholder="Humanoid Blockers" 
        dropdownItems={humanoidBlockersData} 
        sizeValue={humanoidBlocker.size} 
        sizeOnClick={(e)=>setHumanoidBlocker({name: humanoidBlocker.name, size: e.target.dataset.text})} 
        dontCloseOnChange={true}
        value={humanoidBlocker.name} 
        onClick={(e)=>dispatch(changeClipboard({target: 2, type: e.target.dataset.text, type2: e.target.dataset.text2, blockerState: Number(e.target.dataset.blockerstate)}))}
      />

      <InputSelectItem 
        placeholder="Random Humanoid Blockers" 
        dropdownItems={randomHumanoidBlockersData} 
        sizeValue={randomHumanoidBlocker.size} 
        sizeOnClick={(e)=>setRandomHumanoidBlocker({name: randomHumanoidBlocker.name, size: e.target.dataset.text, amount: randomHumanoidBlocker.amount})} 
        amountValue={randomHumanoidBlocker.amount} 
        amountOnChange={(val)=>setRandomHumanoidBlocker({name: randomHumanoidBlocker.name, size: randomHumanoidBlocker.size, amount: val})} 
        selectedMoves={selectedMoves}
        selectedMovesOnClick={(e)=>changeCell(e.target.dataset.key)}
        dontCloseOnChange={true}
        value={randomHumanoidBlocker.name} 
        onClick={(e)=>setRandomHumanoidBlocker({name: e.target.dataset.text, size: randomHumanoidBlocker.size, amount: randomHumanoidBlocker.amount})}
      />
    </Box>
  )
}

export default Blockers