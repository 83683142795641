import { FC } from "react"
import { useNavigate } from "react-router-dom"

import { useDispatch } from "react-redux"
import { resetLevel } from "../redux/levelSlice"
import { changeEditable } from "../redux/editorSlice"
import { InterfaceNotification } from "../typescript/interfaceNotification"

import {Box, Heading, Text} from "@chakra-ui/react"
import InputButtonIcon from "./Inputs/InputButtonIcon"

interface Props {
  width?: string
  margin?: string
  showButtonEdit?: boolean
  showButtonShow?: boolean
  showNotificationText?: boolean
  showLevel?: boolean
  showBiome?: boolean
  showCreatedBy?: boolean
  showDescription?: boolean
  showMessage?: boolean
  levelData: InterfaceNotification
}

const NotificationCard:FC<Props> = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  return (
    <Box m={props.margin ? props.margin : "0 0 20px 0"} w={props.width ? props.width : "100%"}>
      {
        props.showNotificationText && <Text fontSize="12px" fontWeight="600" mb="10px">{props.levelData.message}</Text>
      }
      <Box width="100%" border="2px solid #fff" p="0 16px" borderRadius="8px">

        {
          (props.showLevel || props.showButtonEdit || props.showButtonShow) &&
          <Box display="flex" flexDir="column">
            <Box display="flex" flexDir="row" alignItems="center" p="10px 0">

              {
                props.showLevel && 
                <>
                  <Heading as="h4" fontSize="12px" fontWeight="600" mr="5px" display="inline">Level:</Heading>
                  <Text fontSize="12px" fontWeight="400" m="0" display="inline">{props.levelData.level.number}</Text>
                </>
              }

              {
                (props.showButtonEdit || props.showButtonShow) &&
                <Box marginLeft="auto">
                  {
                    props.showButtonEdit && <InputButtonIcon icon='edit' onClick={()=>console.log("edit level")}/>
                  }
                  {
                    props.showButtonShow && <InputButtonIcon icon='show' onClick={()=>{
                      dispatch(changeEditable(false))
                      dispatch(resetLevel())
                      navigate(`/levels/edit-level/${props.levelData.level.id}`)
                    }}/>
                  }
                </Box>
              }

            </Box>
          </Box>
        }

        {
          (props.showBiome || props.showCreatedBy) &&
          <Box display="flex" flexDir="column" borderTop="1px solid #fff" p="10px 0">
            {
              (props.showBiome && (props.levelData.level.biome || props.levelData.level.gameMode)) &&
              <Box>
                <Heading as="h4" fontSize="12px" fontWeight="600" mr="5px" display="inline">Biome:</Heading>
                <Text fontSize="12px" fontWeight="400" m="0" display="inline">{props.levelData.level.biome} {props.levelData.level.gameMode && `(${props.levelData.level.gameMode})`}</Text>
              </Box>
            }

            {
              (props.showCreatedBy && props.levelData.level.createdBy) &&
              <Box>
                <Heading as="h4" fontSize="12px" fontWeight="600" mr="5px" display="inline">Created By:</Heading>
                <Text fontSize="12px" fontWeight="400" m="0" display="inline">{props.levelData.level.createdBy}</Text>
              </Box>
            }
          </Box>
        }

        {
          (props.showDescription && props.levelData.level.description) &&
          <Box display="flex" flexDir="column" borderTop="1px solid #fff" p="10px 0">
            <Box>
              <Heading as="h4" fontSize="12px" fontWeight="600" mr="5px" display="inline">Description:</Heading>
              <Text fontSize="12px" fontWeight="400" m="0" display="inline">{props.levelData.level.description}</Text>
            </Box>
          </Box>
        }

        {
          (props.showMessage && props.levelData.level.lastMessage?.message) &&
          <Box display="flex" flexDir="column" borderTop="1px solid #fff" p="10px 0">
            <Box>
              <Heading as="h4" fontSize="12px" fontWeight="600" mr="5px" display="inline">Message:</Heading>
              <Text fontSize="12px" fontWeight="400" m="0" display="inline">{props.levelData.level.lastMessage?.message}</Text>
            </Box>
          </Box>
        }

      </Box>
    </Box>
  )
}

export default NotificationCard